import { createTheme, adaptV4Theme } from '@mui/material/styles';       

export default function Theme () {
    return createTheme(adaptV4Theme({
        palette: {
            common: {
                black: "rgba(74, 74, 74, 1)",
                white: "#fff"
            },
            background: {
                paper: "#fff",
                default: "#fafafa"
            },
            primary: {
                light: "rgba(134, 226, 194, 1)",
                main: "rgba(84, 176, 146, 1)",
                dark: "rgba(28, 128, 100, 1)",
                contrastText: "#fff"
            },
            secondary: {
                light: "rgba(102, 78, 176, 1)",
                main: "rgba(52, 37, 128, 1)",
                dark: "rgba(0, 0, 83, 1)",
                contrastText: "#fff"
            },
            success: {
                light: "rgba(134, 226, 194, 1)",
                main: "rgba(84, 176, 146, 1)",
                dark: "rgba(28, 128, 100, 1)",
                contrastText: "#fff"
            },
            error: {
                light: "rgba(203, 77, 75, 1)",
                main: "rgba(191, 33, 30, 1)",
                dark: "rgba(133, 23, 21, 1)",
                contrastText: "#fff"
            },
            text: {
                primary: "rgba(74, 74, 74, 1)",
                secondary: "rgba(112, 112, 112, 1)",
                disabled: "rgba(0, 0, 0, 0.38)",
                hint: "rgba(0, 0, 0, 0.38)"
            },
        },
    }));
}