import React, { useState } from 'react'
import Link from '@mui/material/Link'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import { navBarStyles } from '../style/ComponentStyles'


export default function PrivateMenu() {
    const getOptions = () => {
        if (localStorage.getItem('tags') && localStorage.getItem('funds')) {
            return JSON.parse(localStorage.getItem('tags')).map(tag => tag.name).concat(JSON.parse(localStorage.getItem('funds')).map(fund => fund.name))
        }
        return []
    }
    const classes = navBarStyles()
    const [searchTerm, setSearchTerm] = useState('')
    const [options] = useState(getOptions())
    const [hasFocus, setHasFocus] = useState(false)
    const sendSearch = term => {
        setHasFocus(false)
        const loc = window.location
        if (term.indexOf('#') === 0) {
            return window.location.href = `${loc.protocol}//${loc.host}/tags/${term.split('#')[1]}`
        }
        window.location.href = `${loc.protocol}//${loc.host}/search/${term}`
    }
    return(
        <div className={classes.grow}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <Autocomplete
                    id="search"
                    freeSolo
                    classes={{
                        listbox: classes.listbox,
                        popper: classes.popper,
                    }}
                    open={searchTerm.length > 0 && hasFocus}
                    options={options}
                    onChange={(ev, value, reason) => { sendSearch(value) }}
                    onBlur={() => setHasFocus(false)}
                    onFocus={() => setHasFocus(true)}
                    renderInput={(params) => (
                        <InputBase
                            placeholder="Search..."
                            label="Search..."
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{...params.inputProps, 'aria-label': 'search' }}
                            ref={params.InputProps.ref}
                            onChange={(ev) => { setSearchTerm(ev.target.value) }}
                        />
                    )}
                />
                
            </div>
            <div className={classes.sectionDesktop}>
                <Typography className={classes.root}>
                    <Link href="/funds" color="inherit">Funds</Link>
                    <Link href="/portfolios" color="inherit">Portfolios</Link>
                    <Link href="/tags" color="inherit">Tags</Link>
                    <Link href="/podcast" color="inherit">Podcast</Link>
                    {/*<Link href="/conferences" color="inherit">Conferences</Link>*/}
                </Typography>
            </div>
        </div>
    )
}