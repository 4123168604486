import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import AudioPlayer from 'react-h5-audio-player'
import { fundStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


export default function PodcastEpisode(props) {
    let { guid } = useParams()
    const ref = useRef(null)
    const [data, setData] = useState([])
    const [status, setStatus] = useState('loading')
    useEffect(() => {
        if (navigator.onLine) {
            axios
                .get(`${api_root}/podcasts/${guid}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                        setData(result.data)
                        setStatus('ready')
                        const actionBody = {
                            action_type: 'visit',
                            description: `podcast episode ${result.data.title}`,
                            fund: result.data.fund ? result.data.fund.slug : null,
                            user: localStorage.getItem('user-email')
                        }
                        return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                    })
                    .then(result => {})
                    .catch(err => {
                        const loc = window.location
                        if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                        if (err.response.status === 404) window.location.href = loc.protocol + "//" + loc.host + '/podcast'
                        setStatus('ready')
                    })
        } else {
            ref.current.showSpinner(false)
            setStatus('ready')
        }
    }, [])
    const onPlayPodcast = () => {
        const actionBody = {
            action_type: 'click',
            description: `play on podcast episode ${data.title}`,
            fund: data.fund ? data.fund.slug : null,
            user: localStorage.getItem('user-email')
        }
        return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(res => {})
            .catch(err => {})
    }
    const classes = fundStyles()
    return (
        <div className={classes.fundContainer}>
          { status !== 'loading' ?
          <CommonContainer>
              <Paper elevation={0} className={classes.fundPaper}>
                <Grid container>
                    <Grid item className={classes.fundItem}>
                        <Typography variant="h4" align="center" gutterBottom>
                            HedgeVista Podcast
                        </Typography>
                        { data != null ? <Card className={status === 'loading' ? classes.loading : classes.fundCard}>
                            <CardContent>
                                <h2>
                                    { data.title }
                                </h2>
                                <p>{data.description}</p>
                                <AudioPlayer onPlay={onPlayPodcast} src={data.mp3 != null && data.mp3.provider === 'local' ? `${api_root}${data.mp3.url}` : data.mp3.url} customVolumeControls={[]} customAdditionalControls={[]} />
                                { data.fund ?
                                <div>
                                    <h2>{data.fund.name}</h2>
                                    <p>{data.fund.short_description}</p>
                                    <p><Link href={`/funds/${data.fund.slug}`} color="primary">Learn more about this fund.</Link></p>
                                </div>
                                : null }
                            </CardContent>
                        </Card> : <p>Loading...</p>
                        }
                    </Grid>
                </Grid>
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
          </CommonContainer> : null }
              <Backdrop className={classes.backdrop} open={status === 'loading' ? true : false}>
                <CircularProgress color="inherit" disableShrink />
              </Backdrop>
      </div>
    )
}