import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import CardMedia from '@mui/material/CardMedia'
import { fundCardsStyles } from '../style/ComponentStyles'
import { api_root } from '../config'


export default function FundCards(props) {
    const classes = fundCardsStyles()
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" align="left" gutterBottom className={classes.title}>{props.title}</Typography>
            </Grid>
                {
                    props.funds.length ?
                    props.funds.map(fund => 
                        <Grid item xs={12} sm={6} key={fund.slug}>
                            <CardActionArea className={classes.action} href={`/funds/${fund.slug}`}>
                                <Card className={classes.root}>
                                    <CardMedia
                                        className={classes.media}
                                        image={fund.logo ? `${fund.logo.url}` : `${api_root}/admin/df0cab2dce72865fb8d85ee4beb04ec9.png`}
                                        text={`${fund.name} logo`}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            {fund.name}
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            {fund.short_description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                    ) : null
                }
                </Grid>
    )
}