import React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { navBarStyles } from '../style/ComponentStyles'


export default function PublicMenu() {
    const classes = navBarStyles()
    return(
        <Typography className={classes.root}>
            <Link href="/login" color="inherit">Login</Link>
            <Link href="/contact" color="inherit">Contact</Link>
        </Typography>
    )
}