import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import CardMedia from '@mui/material/CardMedia'
import { fundCardsStyles } from '../style/ComponentStyles'
import { api_root } from '../config'


export default function PodcastCards(props) {
    const classes = fundCardsStyles()
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" align="left" gutterBottom className={classes.title}>HedgeVista Podcast</Typography>
            </Grid>
                {
                    props.podcasts.length ?
                    props.podcasts.map(podcast => 
                        <Grid item xs={12} sm={6} key={podcast.guid}>
                            <CardActionArea className={classes.action} href={`/podcast/${podcast.guid}`}>
                                <Card className={classes.root}>
                                    <CardMedia
                                        className={classes.media}
                                        image={podcast.image ? (podcast.image.provider === 'local' ? `${api_root}${podcast.image.url}` : podcast.image.url) : `${api_root}/admin/df0cab2dce72865fb8d85ee4beb04ec9.png`}
                                        text={`${podcast.title} logo`}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            {podcast.title}
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            {podcast.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                    ) : null
                }
                </Grid>
    )
}