import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { careersStyles } from '../../style/ContainerStyles'
import Link from '@mui/material/Link'
import CommonContainer from '../common/CommonContainer'


export default function Careers() {
    const classes = careersStyles()
    return (
        <div className={classes.careersContainer}>
            <CommonContainer>
                <Paper elevation={0} className={classes.careersPaper}>
                    <Card className={classes.careersCard}>
                        <CardContent>
                            <Typography variant="h4" align="center" gutterBottom>
                            Careers at HedgeVista
                            </Typography>
                            <p>
                                <strong>Life at HedgeVista</strong>
                            </p>
                            <p>
                                We are a global, fast-growing start-up with a relaxed, but hardworking and client-focused culture. We create a platform for innovation and offer an opportunity to become influential in the market. We operate a close-knit working environment, where everyone is treated with respect.
                            </p>
                            <p>
                                <strong>Current Vacancies</strong>
                            </p>
                            <ul>
                                <li><Link href="/careers/research-analyst" color="primary">Research Associate/Research Analyst</Link></li>
                                <li><Link href="/careers/marketing-executive" color="primary">Marketing Executive</Link></li>
                            </ul>
                        </CardContent>
                    </Card>
                </Paper>
            </CommonContainer>
        </div>
    )
}