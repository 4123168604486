import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Container from '@mui/material/Container'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger'
import useMediaQuery from '@mui/material/useMediaQuery'
import Slide from '@mui/material/Slide'
import Fab from '@mui/material/Fab'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Zoom from '@mui/material/Zoom'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import axios from 'axios'
import NavBar from '../../components/NavBar'
import { commonContainerStyles } from '../../style/ContainerStyles'
import BottomNavBar from '../../components/BottomNavBar'
import MobileBottomNav from '../../components/MobileBottomNav'
import Theme from '../../style/Theme'
import { api_root } from '../../config'


function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })
  return (
    <Slide appear={false} direction={"down"} in={!trigger}>
      {children}
    </Slide>
  )
}

function ScrollTop(props) {
  const { children, window } = props
  const classes = commonContainerStyles()
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })
  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.upButton}>
        {children}
      </div>
    </Zoom>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default forwardRef((props, ref) => {
    const [open, setOpen] = useState(false)
    const [isOffline, setIsOffline] = useState(false)
    const showSpinner = (status) => setOpen(status)
    const showOffline = (status) => setIsOffline(status)
    const max800 = useMediaQuery('(max-width:800px)')
    useImperativeHandle(ref, () => {
        return {
          showSpinner,
          showOffline
        }
    })
    const section = props.section
    const showMobileBottomNav = localStorage.getItem('token') && max800 ? true : false
    const theme = Theme()
    const classes = commonContainerStyles()
    const token = localStorage.getItem('token')
    const funds = localStorage.getItem('funds')
    const tags = localStorage.getItem('tags')
    const lastUpdate = localStorage.getItem('lastUpdate')
    const update = new Date(lastUpdate)
    const today = new Date()
    if (token && ([funds, tags, lastUpdate].some(v => !v) || (update.getDate() !== today.getDate() || update.getMonth() !== today.getMonth()))) {
      axios.get(`${api_root}/funds`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        .then(res => {
          localStorage.setItem('funds', JSON.stringify(res.data))
          return axios.get(`${api_root}/hash-tags`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        })
        .then(res => {
          localStorage.setItem('tags', JSON.stringify(res.data))
          localStorage.setItem('lastUpdate', (new Date()).toDateString())
        })
        .catch(err => {
          const loc = window.location
          if (!err.response) return 'offline'
          if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
        })
    }
    return (
      <div className={classes.background}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <HideOnScroll {...props}>
              <div id="back-to-top-anchor"><NavBar /></div>
            </HideOnScroll>
            <Container className={classes.mainContainer}>
              <Snackbar open={isOffline} autoHideDuration={6000} onClose={() => setIsOffline(false)}>
                <Alert severity='error'>You are in offline mode. Some functions are not available whilst offline.</Alert>
              </Snackbar>
              { props.children }
            </Container>
            <ScrollTop {...props}>
              <Fab color="secondary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollTop>
            { showMobileBottomNav ? <MobileBottomNav section={section}/> : <BottomNavBar /> }
            <Backdrop className={classes.backdrop} open={open}>
              <CircularProgress color="inherit" disableShrink />
            </Backdrop> 
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
})