import React, { Fragment, useState, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import axios from 'axios'
import { loginStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


export default function Login(props) {
    const ref = useRef(null)
    const [status, setStatus] = useState(localStorage.getItem('token') != null ? 'expired' : 'ready')
    const [email, setEmail] = useState('')
    const [showErrors, setShowErrors] = useState(false)
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const sendLogin = () => {
        setShowErrors(false)
        if (email === '' || !emailRegExp.test(email)) {
            setEmail('')
            setStatus('ready')
            return setShowErrors(true)
        }
        ref.current.showSpinner(true)
        setStatus('sending')
        axios
            .post(`${api_root}/auth/local`, { provider: 'passwordless', email, brand: 'HV2', domain: 'hv2.pages.dev' })
            .then(result => {
                setStatus('sent')
                setEmail('')
                ref.current.showSpinner(false)
            })
            .catch(err => {
                setStatus('error')
                ref.current.showSpinner(false)
            })
    }
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
            sendLogin()
        }
    }
    const classes = loginStyles()
    delete localStorage['token']
    return (
        <div className={classes.loginContainer}>
            <CommonContainer ref={ref} section='login'>
                <Paper elevation={0} className={classes.loginPaper}>
                    <Card className={classes.loginCard}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Sign into HV2
                            </Typography>
                            { status === 'expired' ? <Typography color="error" gutterBottom>Your session has expired. Please sign in again.</Typography> : null }
                            { status === 'error' ? <Typography color="error" gutterBottom>Something went wrong.<br />We probably don't have an account registered with that email address.</Typography> : null }
                            { status === 'sent' ?
                                <Fragment>
                                    <Typography color="primary" gutterBottom>
                                        <strong>
                                            We sent an email to you at the address you provided. It has a magic link that'll sign you in without messing around with passwords.
                                        </strong>
                                    </Typography>
                                    <Typography color="textPrimary" gutterBottom>
                                        If you can not locate the email in your inbox, please check your spam or junk mail folders and be sure to add this email address to your safe senders list. 
                                    </Typography>
                                </Fragment> : null
                            }
                            <div className={status === 'sent' ? classes.hidden : classes.visible }>
                                <form noValidate autoComplete="on">
                                    <FormControl fullWidth>
                                        <TextField
                                            id="email"
                                            label="Email Address"
                                            error={showErrors && email === ''}
                                            helperText={showErrors && email === '' ? 'Your email address is required' : null}
                                            onChange={() => setEmail(document.getElementById('email').value)}
                                            onKeyDown={onKeyDown}
                                        />
                                    </FormControl>
                                </form>
                            </div>
                        </CardContent>
                        <CardActions className={classes.action}>
                            <div className={status === 'sent' ? classes.hidden : classes.visible }>
                                <Button variant="contained" color="primary" className={classes.button} onClick={sendLogin}>Sign into your account</Button>
                            </div>
                        </CardActions>
                    </Card>
                </Paper>
            </CommonContainer>
        </div>
    )
}