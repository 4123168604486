import React, { useEffect, useState, Fragment } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { DataGrid } from '@mui/x-data-grid'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import showdown from 'showdown'
import axios from 'axios'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, PieChart, Pie, ResponsiveContainer } from 'recharts'
import { useParams } from 'react-router-dom'
import { fundStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export default function Portfolio(props) {
    const { slug } = useParams()
    const [showSnack, setShowSnack] = useState(false)
    const [respondedToSnack, setRespondedToSnack] = useState(false)
    const [snackConfirmation, setSnackConfirmation] = useState(null)
    const [data, setData] = useState({})
    const [rows, setRows] = useState([])
    const [returns, setReturns] = useState([])
    const [growthOfInvestment, setGrowthOfInvestment] = useState([])
    const [approach, setApproach] = useState([])
    const [region, setRegion] = useState([])
    const [strategy, setStrategy] = useState([])
    const [assetClass, setAssetClass] = useState([])
    const [industry, setIndustry] = useState([])
    const [marketEnvironment, setMarketEnvironment] = useState([])
    const [status, setStatus] = useState('loading')
    const [tiles, setTiles] = useState([])
    const [isSmallScreen] = useState(window.visualViewport && window.visualViewport.width < 700)
    const allTags = [
        {
            category: 'Region/Country',
            tags: [
                { label: 'APAC', value: '#APAC' },
                { label: 'Australia', value: '#Australia' },
                { label: 'China', value: '#China' },
                { label: 'Europe', value: '#Europe' },
                { label: 'Global', value: '#Global' },
                { label: 'India', value: '#India' },
                { label: 'Japan', value: '#Japan' },
                { label: 'Korea', value: '#Korea' },
                { label: 'Latin America (LATAM)', value: '#LATAM' },
                { label: 'Nordics', value: '#Nordics' },
                { label: 'South East Asia', value: '#SouthEastAsia' },
                { label: 'US', value: '#US' }
            ]
        },
        {
            category: 'Asset Class',
            tags: [
                { label: 'Commodity', value: '#Commodity' },
                { label: 'Credit/Fixed Income', value: '#CreditFixedIncome' },
                { label: 'Crypto/Blockchain', value: '#CryptoBlockchain' },
                { label: 'Equity', value: '#Equity' },
                { label: 'Fixed Income Alternative', value: '#FixedIncomeAlternative' },
                { label: 'FX', value: '#FX' },
                { label: 'Multi-Asset', value: '#MultiAsset' },
                { label: 'Rates', value: '#Rates' },
                { label: 'Volatility', value: '#Volatility' }
            ]
        },
        {
            category: 'Strategy',
            tags: [
                { label: 'Activist/Engagement', value: '#ActivistEngagement' },
                { label: 'Arbitrage', value: '#Arbitrage' },
                { label: 'Event Driven', value: '#EventDriven' },
                { label: 'Long Bias', value: '#LongBias' },
                { label: 'Long Only', value: '#LongOnly' },
                { label: 'Long Short', value: '#LongShort' },
                { label: 'Low Net', value: '#LowNet' },
                { label: 'Macro', value: '#Macro' },
                { label: 'Market Neutral', value: '#MarketNeutral' },
                { label: 'Merger Arbitrage', value: '#MergerArbitrage' },
                { label: 'Multi-Strategy', value: '#MultiStrategy' },
                { label: 'Pairs', value: '#Pairs' },
                { label: 'Relative Value', value: '#RelativeValue' },
                { label: 'Variable Net', value: '#VariableNet' }
            ]
        },
        {
            category: 'Approach',
            tags: [
                { label: 'Buy-Hold', value: '#BuyHold' },
                { label: 'Discretionary', value: '#Discretionary' },
                { label: 'Fundamental', value: '#Fundamental' },
                { label: 'Growth', value: '#Growth' },
                { label: 'Nimble', value: '#Nimble' },
                { label: 'Quantitative', value: '#Quantitative' },
                { label: 'Systematic', value: '#Systematic' },
                { label: 'Tactical Trading', value: '#TacticalTrading' },
                { label: 'Value', value: '#Value' }
            ]
        },
        {
            category: 'Industry',
            tags: [
                { label: 'Aerospace', value: '#Aerospace' },
                { label: 'AI', value: '#AI' },
                { label: 'Biotechnology', value: '#Biotechnology' },
                { label: 'Cleantech', value: '#Cleantech' },
                { label: 'Consumer Discretionary', value: '#ConsumerDiscretionary' },
                { label: 'Consumer Staples', value: '#ConsumerStaples' },
                { label: 'Education', value: '#Education' },
                { label: 'ESG', value: '#ESG' },
                { label: 'Financials', value: '#Financials' },
                { label: 'Healthcare', value: '#Healthcare' },
                { label: 'Industrials', value: '#Inustrials' },
                { label: 'Real Estate', value: '#RealEstate' },
                { label: 'Robotics', value: '#Robotics' },
                { label: 'Technology', value: '#Technology' }
            ]
        },
        {
            category: 'Market Environment',
            tags: [
                { label: 'Agnostic', value: '#Agnostic' },
                { label: 'Growth', value: '#Growth' },
                { label: 'High Volatility', value: '#HighVolatility' },
                { label: 'Market Decline', value: '#MarketDecline' },
                { label: 'Stable', value: '#Stable' }
            ]
        }
    ]
    const growth = returnsForPeriod => returnsForPeriod.map(change => 1 + (change / 100)).reduce((a, c) => a * c)
    const stdev = monthlyReturns => {
        const mean = monthlyReturns.reduce((a, c) => a + c) / monthlyReturns.length
        return (Math.sqrt(monthlyReturns.reduce((a, c) => a + ((c - mean) ** 2), 0) / (monthlyReturns.length - 1))) * Math.sqrt(12)
    }
    const maxDrawdown = monthlyReturns => {
        const _maxDrawdown = equityCurve => {
            let highWaterMark = -Infinity
            let maxDd = -Infinity
            for (let i = 0; i < equityCurve.length; ++i) {
                if (equityCurve[i] > highWaterMark) {
                    highWaterMark = equityCurve[i]
                }
                let dd = (highWaterMark - equityCurve[i]) / highWaterMark
                if (dd > maxDd) {
                    maxDd = dd
                }
            }
            return maxDd == -Infinity ? 0 : maxDd
        }
        let nav = 100
        return _maxDrawdown(monthlyReturns.map(r => nav += nav * (r / 100))) * 100
    }
    const calculateMetrics = data => {
        const riskFreeRate = 2
        const sortedReturnValues = data.returns.sort((a, b) => (a.date + 0) - (b.date + 0)).map(r => r.weightedReturn)
        const annualReturn = ((growth(sortedReturnValues) ** (12 / sortedReturnValues.length) - 1) * 100)
        const riskFreeAnnualReturn = annualReturn - riskFreeRate
        const mean = sortedReturnValues.reduce((a, c) => a + c) / sortedReturnValues.length
        const negativeReturnValues = sortedReturnValues.filter(r => r < 0)
        setTiles([
            {
                title: 'ANN. RETURN',
                value: annualReturn.toFixed(1) + '%'
            },
            {
                title: 'AVG.&nbsp;MONTHLY&nbsp;RETURN',
                value: mean.toFixed(1) + '%'
            },
            {
                title: 'MAX. DRAWDOWN',
                value: negativeReturnValues.length ? maxDrawdown(sortedReturnValues).toFixed(1) + '%' : 'N/A'
            },
            {
                title: 'SHARPE RATIO',
                value: (riskFreeAnnualReturn / stdev(sortedReturnValues)).toFixed(1)
            },
            {
                title: 'SORTINO RATIO',
                value: negativeReturnValues.length && negativeReturnValues.length > 1 ? (riskFreeAnnualReturn / stdev(negativeReturnValues)).toFixed(1) : 'N/A'
            },
            {
                title: 'INCEPTION DATE',
                value: new Date(data.inception)
                            .toLocaleDateString('en-GB', {
                                month : 'short',
                                year : 'numeric'
                            })
            }
        ])
    }
    const formatReturns = returns => {
        const returnsData = returns.map(r => ({ year: r.year, month: r.month, change: r.weightedReturn })).sort((a, b) => b.year - a.year)
        const formattedReturns = returnsData.reduce((acc, cur) => {
            if (acc[cur.year] == null) {
                acc[cur.year] = { year: cur.year }
            }
            acc[cur.year][cur.month] = cur.change.toFixed(1)
            return acc
        }, {})
        const sortedReturns = Object.keys(formattedReturns).map(k => formattedReturns[k]).sort((a, b) => b.year - a.year).sort((a, b) => b.month - a.month)
        return sortedReturns
    }
    const formatGrowth = returns => {
        let initialValue = 100
        return returns.sort((a, b) => (a.date + 0) - (b.date + 0)).map(r => {
            initialValue = initialValue * (1 + (r.weightedReturn/100))
            return { name: `${r.year}-${r.month}`, value: initialValue }
        })
    }
    const actionPerformed = (action, resource) => {
        const actionBody = {
            action_type: 'click',
            description: `${action} ${resource}`,
            fund: slug,
            user: localStorage.getItem('user-email')
        }
        axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(result => {})
            .catch(err => {})
    }
    const buildRow = fund => {
        let row = {}
        if ('slug' in fund) {
            row.id = fund.slug
        }
        if ('name' in fund) {
            row['name'] = fund.name
        }
        if ('logo' in fund) {
            if (fund.logo == null) {
                    row['logo'] = 'https://api.hedgevista.com/admin/df0cab2dce72865fb8d85ee4beb04ec9.png'
            } else {
                if ('url' in fund.logo) {
                    row['logo'] = fund.logo.url
                }
            }
        }
        if ('allocation_percentage' in fund) {
            row['allocationPercentage'] = fund['allocation_percentage']
        }
        if ('inception' in fund) {
            row['inception'] = new Date(fund.inception)
                .toLocaleDateString('en-GB', {
                    month : 'short',
                    year : 'numeric'
                })
        }
        return row
    }
    const columns = [
        {
            field: 'logo',
            headerName: '',
            width: 80,
            renderCell: (params) => {
              return (
                <Avatar 
                    src={params.row.logo}
                    alt={params.row.name}
                    sx={{ width: 52, height: 52 }}
                    imgProps={{sx:{ objectFit: 'contain' }}}
                    variant='square' />
              )
            }
        },
        { field: 'name', headerName: 'Investment', width: 470 },
        { field: 'inception', headerName: 'Inception', width: 90, align: 'center' },
        { field: 'allocationPercentage', type: 'number', headerName: 'Allocation (%)', width: 120, align: 'center' }
    ]
    const tagCols = [
        { field: 'name', headerName: '', flex: 1 },
        { field: 'id', headerName: '', hide: true, flex: 0 },
        { field: 'weight', headerName: '', hide: true, flex: 0 }
    ]
    const buildTags = (tags, type) => {
        return allTags.find(t => t.category === type).tags.map(a => {
            const found = tags.find(t => t.name === a.value)
            const weight = found != null ? found.funds.reduce((a, c) => a += c.weight, 0) : null
            return { name: a.label, id: a.label, weight }
        }).filter(a => a.weight != null).sort((a, b) => b.weight - a.weight)
    }
    const buildRegion = (tags) => {
        const regions = buildTags(tags, 'Region/Country')
        const totalWeight = regions.reduce((acc, cur) => acc += cur.weight, 0)
        return regions.map(r => ({ name: r.name, id: r.id, weight: (r.weight/totalWeight)*100 }))
    }
    useEffect(() => {
        setStatus('loading')
        if (navigator.onLine) {
            axios
                .get(`${api_root}/portfolios/${slug}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                    setData(result.data)
                    setRows(result.data.funds.map(fund => buildRow(fund)))
                    if (result.data.message !== 'wrong user type') {
                        setReturns(formatReturns(result.data.returns))
                        if (result.data.returns.length) {
                            calculateMetrics(result.data)
                            setGrowthOfInvestment(formatGrowth(result.data.returns))
                            setApproach(buildTags(result.data.tags, 'Approach'))
                            setStrategy(buildTags(result.data.tags, 'Strategy'))
                            setIndustry(buildTags(result.data.tags, 'Industry'))
                            setAssetClass(buildTags(result.data.tags, 'Asset Class'))
                            setRegion(buildRegion(result.data.tags))
                            setMarketEnvironment(buildTags(result.data.tags, 'Market Environment'))
                        }
                        setRespondedToSnack(localStorage.getItem(result.data.slug + '-snack') ? true : false)
                        localStorage.setItem(slug, JSON.stringify(result.data))
                    }
                    setStatus('ready')
                    const actionBody = {
                        action_type: 'visit',
                        description: `${slug} portfolio page`,
                        fund: null,
                        user: localStorage.getItem('user-email')
                    }
                    return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                })
                .then(result => {
                   // 
                })
                .catch(err => {
                    const loc = window.location
                    if (!err.response) {
                        const data = JSON.parse(localStorage.getItem(slug))
                        if (data != null) {
                            setData(data)
                            setReturns(formatReturns(data.returns))
                            calculateMetrics(data)
                            setStatus('ready')
                        } else {
                            setStatus('no data')
                        }
                        return 'offline'
                    }
                    if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                    if (err.response.status === 404) window.location.href = loc.protocol + "//" + loc.host + '/funds'
                    setStatus('ready')
                })
        } else {
            const data = JSON.parse(localStorage.getItem(slug))
            if (data != null) {
                setData(data)
                setReturns(formatReturns(data.returns))
                calculateMetrics(data)
                setStatus('ready')
            } else {
                setStatus('no data')
            }
            setRespondedToSnack(localStorage.getItem(slug + '-snack') ? true : false)
        }
    }, [slug])
    const navigate = params => {
        const loc = window.location
        return window.location.href = `${loc.protocol}//${loc.host}/funds/${params.row.id}`
    }
    const converter = new showdown.Converter()
    const classes = fundStyles()
    const handleClose = message => () => {
        const subject = `Response (${message.toUpperCase()}) to learn more message`
        const name = localStorage.getItem('user-name')
        const email = localStorage.getItem('user-email')
        let messageBody = `Fund: ${data.name}\n${name} responded ${message} when asked if they would like to learn more about the fund.`
        if (message === 'yes' || message === 'no') localStorage.setItem(data.slug + '-snack', true)
        messageBody += message === 'yes' ? `\n${name} should be connected to ${data.name} and contacted with more information.` : '\nNo action is required at this time.'
        setRespondedToSnack(true)
        axios
            .post(`${api_root}/messages`, { subject, message: messageBody, name, email })
            .then(result => {
                if (message === 'yes') setSnackConfirmation('You will be contacted shortly with more information about this fund.')
                if (message === 'no') setSnackConfirmation('We won\'t ask you about this fund again.')
                if (message === 'later') setSnackConfirmation('We\'ll ask you about this fund another time.')                
                const actionBody = {
                    action_type: 'click',
                    description: `responded "${message}" to prompt for more information`,
                    fund: slug,
                    user: localStorage.getItem('user-email')
                }
                return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            })
            .then(result => {})
            .catch(err => {
                setSnackConfirmation('error')
            })
    }
    useEffect(_ => {
        const handleScroll = _ => {
          const el = document.getElementById('hashTagList')
          const inCorrectPosition = el && el.getBoundingClientRect().bottom <= window.innerHeight
          setShowSnack(inCorrectPosition)
        }
        window.addEventListener('scroll', handleScroll)
        return _ => {
          window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    const replaceVariables = content => {
        const sortedRowValues = Object.keys(returns).length > 0 ? Object.keys(returns[0]).reduce((a, c) => !isNaN(Number(c)) ? a.concat(Number(c)) : a, []).sort((a, b) => a - b).map(k => returns[0][k < 10 ? '0' + k : k]) : null
        const variables = tiles.length > 0 && sortedRowValues != null ? {
            SHARPE_RATIO: tiles.find(t => t.title === 'SHARPE RATIO').value,
            SORTINO_RATIO: tiles.find(t => t.title === 'SORTINO RATIO').value,
            ANN_RETURN: tiles.find(t => t.title === 'ANN. RETURN').value,
            MAX_DRAWDOWN: tiles.find(t => t.title === 'MAX. DRAWDOWN').value,
            CUM_RETURN: ((growth(data.returns.sort((a, b) => (a.date + 0) - (b.date + 0)).map(r => r.weightedReturn)) - 1) * 100).toFixed(1) + '%',
            CURRENT_YTD: ((growth(sortedRowValues) - 1) * 100).toFixed(1) + '%'
        } : null
        return variables != null ? Object.keys(variables).reduce((acc, cur) => {
            const regex = new RegExp(cur, 'gi')
            return acc.replace(regex, variables[cur])
        }, content) : content
    }
    return (
      <div className={classes.fundContainer}>
          <CommonContainer>
              <Paper elevation={0} className={classes.fundPaper}>
                <Grid container>
                { data.message !== 'wrong user type' ?
                    <Grid item className={classes.fundItem}>
                        <Typography variant="h4" align="center" gutterBottom>
                            {status === 'no data' ? 'Only funds that have been visited before are available whilst offline' : status=== 'loading' ? '' : `${data.name} Portfolio`}
                        </Typography>
                        { status !== 'no data' ?
                        <Card className={status === 'loading' ? classes.loading : classes.fundCard}>
                            <CardContent>
                                <div className={classes.tileRoot}>
                                    <ImageList rowHeight={120} className={classes.gridList} cols={isSmallScreen ? 2 : 3}>
                                        {tiles.map((tile) => (
                                        <ImageListItem key={tile.title} className={classes.tile}>
                                            <div className={classes.tileContents}>
                                                <p className={classes.tileTitle} dangerouslySetInnerHTML={{__html: tile.title}} />
                                                <h2 className={classes.tileValue}>{tile.value}</h2>
                                            </div>  
                                        </ImageListItem>
                                        ))}
                                    </ImageList>
                                </div>
                                <p><em>Sharpe and Sortino ratios assume 2% risk free rate.</em></p>
                                <div dangerouslySetInnerHTML={{__html: converter.makeHtml(replaceVariables(data.description))}} />
                                <h2>
                                    Performance Summary (%)
                                </h2>
                                {returns == null ? null :
                                    <Fragment>
                                    <TableContainer>
                                    <Table className={classes.table} size="small" aria-label="Monthly returns">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className={classes.tableHeader}>Year</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Jan</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Feb</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Mar</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Apr</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>May</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Jun</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Jul</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Aug</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Sep</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Oct</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Nov</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Dec</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>YTD</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {returns.map((row) => {
                                          const sortedRowValues = Object.keys(row).reduce((a, c) => !isNaN(Number(c)) ? a.concat(Number(c)) : a, []).sort((a, b) => a - b).map(k => row[k < 10 ? '0' + k : k])
                                          const ytd = ((growth(sortedRowValues) - 1) * 100).toFixed(1)
                                          return (
                                          <TableRow key={row.year}>
                                            <TableCell component="th" scope="row" className={classes.tableHeader}>
                                              {row.year}
                                            </TableCell>
                                            <TableCell align="right" className={ row["01"] > 0 ? classes.positive : classes.negative }>{row["01"]}</TableCell>
                                            <TableCell align="right" className={ row["02"] > 0 ? classes.positive : classes.negative }>{row["02"]}</TableCell>
                                            <TableCell align="right" className={ row["03"] > 0 ? classes.positive : classes.negative }>{row["03"]}</TableCell>
                                            <TableCell align="right" className={ row["04"] > 0 ? classes.positive : classes.negative }>{row["04"]}</TableCell>
                                            <TableCell align="right" className={ row["05"] > 0 ? classes.positive : classes.negative }>{row["05"]}</TableCell>
                                            <TableCell align="right" className={ row["06"] > 0 ? classes.positive : classes.negative }>{row["06"]}</TableCell>
                                            <TableCell align="right" className={ row["07"] > 0 ? classes.positive : classes.negative }>{row["07"]}</TableCell>
                                            <TableCell align="right" className={ row["08"] > 0 ? classes.positive : classes.negative }>{row["08"]}</TableCell>
                                            <TableCell align="right" className={ row["09"] > 0 ? classes.positive : classes.negative }>{row["09"]}</TableCell>
                                            <TableCell align="right" className={ row["10"] > 0 ? classes.positive : classes.negative }>{row["10"]}</TableCell>
                                            <TableCell align="right" className={ row["11"] > 0 ? classes.positive : classes.negative }>{row["11"]}</TableCell>
                                            <TableCell align="right" className={ row["12"] > 0 ? classes.positive : classes.negative }>{row["12"]}</TableCell>
                                            <TableCell align="right" className={ ytd > 0 ? classes.positive : classes.negative }>{ytd}</TableCell>
                                          </TableRow>
                                        )})}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  <p><em>The portfolio is rebalanced monthly. In the case that the portfolios inception predates any of it's constituent funds the percentage has been allocated pro rata across the other funds in the portfolio.</em></p>
                                  <h2>Growth of $100,000 since inception</h2>
                                  <ResponsiveContainer width="100%" height={240}>
                                        <AreaChart
                                            data={growthOfInvestment}
                                        >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis label={{ value: '$ in thousands', angle: -90, position: 'insideBottomLeft', offset: 20}} />
                                        <Area type="monotone" dataKey="value" stroke="#342580" fill="#342580" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                  </Fragment>
                                }
                                <h2>Funds</h2>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    onRowClick={navigate}
                                    autoHeight={true}
                                    hideFooter={true}
                                    editMode={'row'}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <h2>Approach</h2>
                                        <ResponsiveContainer width="100%" height={300}>
                                            <RadarChart cx="50%" cy="50%" outerRadius="100%" data={approach}>
                                            <PolarGrid />
                                            <PolarAngleAxis dataKey="name" orientation="inner" />
                                            <PolarRadiusAxis />
                                            <Radar name="Approach" dataKey="weight" stroke="#342580" fill="#342580" fillOpacity={0.6} />
                                            </RadarChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <h2>Strategy</h2>
                                        <DataGrid
                                            rows={strategy}
                                            columns={tagCols}
                                            autoHeight={true}
                                            hideFooter={true}
                                            headerHeight={0}
                                            editMode={'row'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <h2>Region</h2>
                                        <ResponsiveContainer width="100%" height={250}>
                                            <PieChart>
                                            <Pie
                                                dataKey="weight"
                                                isAnimationActive={false}
                                                data={region}
                                                cx="50%"
                                                cy="50%"
                                                outerRadius={96}
                                                fill="#54B092"
                                                label={r => r.name}
                                            />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <h2>Asset Class</h2>
                                        <DataGrid
                                            rows={assetClass}
                                            columns={tagCols}
                                            autoHeight={true}
                                            hideFooter={true}
                                            headerHeight={0}
                                            editMode={'row'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <h2>Industry</h2>
                                        <DataGrid
                                            rows={industry}
                                            columns={tagCols}
                                            autoHeight={true}
                                            hideFooter={true}
                                            headerHeight={0}
                                            editMode={'row'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <h2>Market Environment</h2>
                                        <DataGrid
                                            rows={marketEnvironment}
                                            columns={tagCols}
                                            autoHeight={true}
                                            hideFooter={true}
                                            headerHeight={0}
                                            editMode={'row'}
                                        />
                                    </Grid>
                                </Grid>
                                <Snackbar
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                    }}
                                    className={classes.snackbar}
                                    open={showSnack && !respondedToSnack && navigator.onLine}
                                    message="Would you like to learn more about this Portfolio?"
                                    action={
                                    <React.Fragment>
                                        <Button className={classes.yes} size="small" onClick={handleClose('yes')}>
                                        Yes
                                        </Button>
                                        <Button className={classes.no} size="small" onClick={handleClose('no')}>
                                        No
                                        </Button>
                                        <Button className={classes.later} size="small" onClick={handleClose('later')}>
                                        Not Now
                                        </Button>
                                    </React.Fragment>
                                    }
                                />
                                <Snackbar
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                    }}
                                    className={classes.snackbar}
                                    open={snackConfirmation !== null}
                                    onClose={() => setSnackConfirmation(null)}
                                    autoHideDuration={5000}
                                >
                                    <Alert severity="success">
                                        Thank you for your response! {snackConfirmation}
                                    </Alert>
                                </Snackbar>
                            </CardContent>
                        </Card> : null }
                    </Grid> :
                    <Grid item className={classes.fundItem}>
                        <Card className={status === 'loading' ? classes.loading : classes.fundCard}>
                            <CardContent>
                                <h2>Your account does not have permission to view this Portfolio.</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                </Grid>
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <Backdrop className={classes.backdrop} open={status === 'loading' ? true : false}>
                <CircularProgress color="inherit" disableShrink />
              </Backdrop>
          </CommonContainer>
      </div>
    )
}