import React, {useEffect, useState} from 'react'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from "react-router-dom"
import axios from 'axios'
import { newsletterStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


export default function Newsletter(props) {
    const { guid } = useParams()
    const [status, setStatus] = useState('loading')
    const [data, setData] = useState({})
    useEffect(() => {
        setStatus('loading')
        axios
            .get(`${api_root}/newsletters/${guid}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(result => {
                setData(result.data)
                setStatus('ready')
            }).catch(err => {
                const loc = window.location
                setStatus('ready')
                if (!err.response) return 'offline'
                if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                if (err.response.status === 404) window.location.href = loc.protocol + "//" + loc.host + '/funds'
            })
    }, [])
    const classes = newsletterStyles()
    return (
      <div className={classes.newsletterContainer}>
          <CommonContainer>
              <Paper elevation={0} className={classes.newsletterPaper}>
                  <Card className={classes.newsletterCard}>
                      <CardContent>
                          <div className={classes.newsletterContent} dangerouslySetInnerHTML={{__html: data.html}} />
                      </CardContent>
                  </Card>
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <Backdrop className={classes.backdrop} open={status === 'loading' ? true : false}>
                <CircularProgress color="inherit" disableShrink />
              </Backdrop>
          </CommonContainer>
      </div>
    )
}