import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import PortfolioCards from '../../components/PortfolioCards'
import { fundListStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


export default function ConferenceList(props) {
    const ref = useRef(null)
    const data = [
        {name: 'Aggressive', slug: 'aggressive' },
        {name: 'Balanced', slug: 'balanced' },
        {name: 'Conservative', slug: 'conservative' },
    ]
    useEffect(() => {
        if (navigator.onLine) {
            const actionBody = {
                action_type: 'visit',
                description: 'portfolio list',
                fund: null,
                user: localStorage.getItem('user-email')
            }
            return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => {})
                
        } else {
            ref.current.showSpinner(false)
        }
    }, [])
    const classes = fundListStyles()
    return(
        <div className={classes.fundContainer}>
            <CommonContainer ref={ref} section='all_podcasts'>
                <Paper elevation={0} className={classes.fundPaper}>
                { data.length ? <PortfolioCards portfolios={data} /> : null }
                </Paper>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </CommonContainer>
        </div> 
    ) 
}