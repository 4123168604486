import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import PodcastCards from '../../components/PodcastCards'
import { fundListStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


export default function ConferenceList(props) {
    const ref = useRef(null)
    const [data, setData] = useState([])
    useEffect(() => {
        ref.current.showSpinner(true)
        if (navigator.onLine) {
            axios
                .get(`${api_root}/podcasts`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                    setData(result.data)
                    ref.current.showSpinner(false)
                    const actionBody = {
                        action_type: 'visit',
                        description: 'podcast list',
                        fund: null,
                        user: localStorage.getItem('user-email')
                    }
                    return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                })
                .then(result => {})
                .catch(err => {
                    const loc = window.location
                    ref.current.showSpinner(false)
                    if (!err.response) {
                        ref.current.showOffline(true)
                        return 'offline'
                    }
                    if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                    if (err.response.status === 404) window.location.href = loc.protocol + "//" + loc.host + '/tags'
                })
        } else {
            ref.current.showSpinner(false)
        }
    }, [])
    const classes = fundListStyles()
    return(
        <div className={classes.fundContainer}>
            <CommonContainer ref={ref} section='all_podcasts'>
                <Paper elevation={0} className={classes.fundPaper}>
                { data.length ? <PodcastCards podcasts={data} /> : null }
                </Paper>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </CommonContainer>
        </div> 
    ) 
}