import React, { useEffect, useState, useRef, Fragment } from 'react'
import Paper from '@mui/material/Paper'
import { DataGrid } from '@mui/x-data-grid'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import PublicIcon from '@mui/icons-material/Public'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import BalanceIcon from '@mui/icons-material/Balance'
import FactoryIcon from '@mui/icons-material/Factory'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import axios from 'axios'
import { fundListStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import FundCards from '../../components/FundCards'
import { api_root } from '../../config'


export default function FundList(props) {
    const ref = useRef(null)
    const filters = [
        {
            category: 'Region/Country',
            icon: <PublicIcon />,
            tags: [
                { label: 'APAC', value: '#APAC' },
                { label: 'China', value: '#China' },
                { label: 'Europe', value: '#Europe' },
                { label: 'Global', value: '#Global' },
                { label: 'India', value: '#India' },
                { label: 'Japan', value: '#Japan' },
                { label: 'Korea', value: '#Korea' },
                { label: 'Latin America (LATAM)', value: '#LATAM' },
                { label: 'Nordics', value: '#Nordics' },
                { label: 'South East Asia', value: '#SouthEastAsia' },
                { label: 'US', value: '#US' }
            ]
        },
        {
            category: 'Asset Class',
            icon: <AttachMoneyIcon />,
            tags: [
                { label: 'Commodity', value: '#Commodity' },
                { label: 'Credit/Fixed Income', value: '#CreditFixedIncome' },
                { label: 'Crypto/Blockchain', value: '#CryptoBlockchain' },
                { label: 'Equity', value: '#Equity' },
                { label: 'Fixed Income Alternative', value: '#FixedIncomeAlternative' },
                { label: 'FX', value: '#FX' },
                { label: 'Multi-Asset', value: '#MultiAsset' },
                { label: 'Rates', value: '#Rates' },
                { label: 'Volatility', value: '#Volatility' }
            ]
        },
        {
            category: 'Strategy',
            icon: <AltRouteIcon />,
            tags: [
                { label: 'Activist/Engagement', value: '#ActivistEngagement' },
                { label: 'Arbitrage', value: '#Arbitrage' },
                { label: 'Event Driven', value: '#EventDriven' },
                { label: 'Long Bias', value: '#LongBias' },
                { label: 'Long Only', value: '#LongOnly' },
                { label: 'Long Short', value: '#LongShort' },
                { label: 'Low Net', value: '#LowNet' },
                { label: 'Macro', value: '#Macro' },
                { label: 'Market Neutral', value: '#MarketNeutral' },
                { label: 'Merger Arbitrage', value: '#MergerArbitrage' },
                { label: 'Multi-Strategy', value: '#MultiStrategy' },
                { label: 'Pairs', value: '#Pairs' },
                { label: 'Relative Value', value: '#RelativeValue' },
                { label: 'Variable Net', value: '#VariableNet' }
            ]
        },
        {
            category: 'Approach',
            icon: <BalanceIcon />,
            tags: [
                { label: 'Buy-Hold', value: '#BuyHold' },
                { label: 'Discretionary', value: '#Discretionary' },
                { label: 'Fundamental', value: '#Fundamental' },
                { label: 'Growth', value: '#Growth' },
                { label: 'Nimble', value: '#Nimble' },
                { label: 'Quantitative', value: '#Quantitative' },
                { label: 'Systematic', value: '#Systematic' },
                { label: 'Tactical Trading', value: '#TacticalTrading' },
                { label: 'Value', value: '#Value' }
            ]
        },
        {
            category: 'Industry',
            icon: <FactoryIcon />,
            tags: [
                { label: 'Aerospace', value: '#Aerospace' },
                { label: 'AI', value: '#AI' },
                { label: 'Biotechnology', value: '#Biotechnology' },
                { label: 'Cleantech', value: '#Cleantech' },
                { label: 'Consumer Discretionary', value: '#ConsumerDiscretionary' },
                { label: 'Consumer Staples', value: '#ConsumerStaples' },
                { label: 'Education', value: '#Education' },
                { label: 'ESG', value: '#ESG' },
                { label: 'Financials', value: '#Financials' },
                { label: 'Healthcare', value: '#Healthcare' },
                { label: 'Industrials', value: '#Inustrials' },
                { label: 'Real Estate', value: '#RealEstate' },
                { label: 'Robotics', value: '#Robotics' },
                { label: 'Technology', value: '#Technology' }
            ]
        },
        {
            category: 'Market Environment',
            icon: <AutoGraphIcon />,
            tags: [
                { label: 'Agnostic', value: '#Agnostic' },
                { label: 'Growth', value: '#Growth' },
                { label: 'High Volatility', value: '#HighVolatility' },
                { label: 'Market Decline', value: '#MarketDecline' },
                { label: 'Stable', value: '#Stable' }
            ]
        }
    ]
    const [data, setData] = useState([])
    const [activeFilters, setActiveFilters] = useState(filters[0].tags.concat(filters[1].tags.concat(filters[2].tags.concat(filters[3].tags.concat(filters[4].tags.concat(filters[5].tags))))).map(f => f.value))
    const [filterCategories, setFilterCategories] = useState([
        { category: 'Region/Country', show: true },
        { category: 'Asset Class', show: true },
        { category: 'Strategy', show: true },
        { category: 'Approach', show: true },
        { category: 'Industry', show: true },
        { category: 'Market Environment', show: true }
    ])
    const toggleCategory = (e) => {
        const category = e.currentTarget.value
        console.log(category)
        const isShown = filterCategories.find(c => c.category === category).show
        const toggledFilters = filters.find(f => f.category === category).tags.map(tag => tag.value)
        let cache = activeFilters
        setFilterCategories(filterCategories.filter(c => c.category !== category).concat({
            category,
            show: !isShown
        }))
        if (isShown) {
            cache = activeFilters.filter(f => !toggledFilters.includes(f))
        } else {
            toggledFilters.forEach(f => {
                if (!cache.includes(f)) {
                    cache.push(f)
                }
            })
        }
        setActiveFilters(cache)
        setData(data.map(fund => {
            fund.show = fund.fundTags.some(tag => cache.includes(tag))
            return fund
        }))
    }
    const growth = returnsForPeriod => returnsForPeriod.map(change => 1 + (change / 100)).reduce((a, c) => a * c)
    const stdev = monthlyReturns => {
        const mean = monthlyReturns.reduce((a, c) => a + c) / monthlyReturns.length
        return (Math.sqrt(monthlyReturns.reduce((a, c) => a + ((c - mean) ** 2), 0) / (monthlyReturns.length - 1))) * Math.sqrt(12)
    }

    const buildRow = fund => {
        let row = {}
        if ('slug' in fund) {
            row.id = fund.slug
        }
        if ('name' in fund) {
            row['name'] = fund.name
        }
        if ('logo' in fund) {
            if (fund.logo == null) {
                    row['logo'] = 'https://api.hedgevista.com/admin/df0cab2dce72865fb8d85ee4beb04ec9.png'
            } else {
                if ('url' in fund.logo) {
                    row['logo'] = fund.logo.url
                }
            }
        }
        if ('trending' in fund) {
            row['trending'] = fund.trending
        }
        if ('annualReturn' in fund) {
            row['annualReturn'] = fund.annualReturn
        }
        if ('annualStd' in fund) {
            row['annualStd'] = fund.annualStd
        }
        if ('twelveMonthReturn' in fund) {
            row['twelveMonthReturn'] = fund.twelveMonthReturn
        }
        if ('sixMonthReturn' in fund) {
            row['sixMonthReturn'] = fund.sixMonthReturn
        }
        if ('threeMonthReturn' in fund) {
            row['threeMonthReturn'] = fund.threeMonthReturn
        }
        return row
    }

    const columns = [
        {
            field: 'logo',
            headerName: '',
            width: 80,
            renderCell: (params) => {
              return (
                <Avatar 
                    src={params.row.logo}
                    alt={params.row.name}
                    sx={{ width: 52, height: 52 }}
                    imgProps={{sx:{ objectFit: 'contain' }}}
                    variant='square' />
              )
            }
        },
        { field: 'name', headerName: 'Investment', width: 285 },
        { field: 'trending', headerName: 'Trending', width: 85, renderCell: params => {
                let color = 'default'
                let value = 'Low'
                if (params.row.trending === 1) {
                    color = 'primary'
                    value = 'High'
                }
                if (params.row.trending === 2) {
                    color = 'secondary'
                    value = 'Medium'
                }
                return (
                    <Chip
                        label={value}
                        color={color}
                        size='small'
                    />
                )
            },
        },
        { field: 'annualReturn', type: 'number', headerName: 'Ann. (%)', width: 75 },
        { field: 'twelveMonthReturn', type: 'number', headerName: '12 mo. (%)', width: 85 },
        { field: 'sixMonthReturn', type: 'number', headerName: '6 mo. (%)', width: 80 },
        { field: 'threeMonthReturn', type: 'number', headerName: '3 mo. (%)', width: 80 },
        { field: 'annualStd', type: 'number', headerName: 'Ann. STD', width: 80 },
      ]
      
    useEffect(() => {
        ref.current.showSpinner(true)
        if (navigator.onLine) {
            axios
                .get(`${api_root}/funds/composite`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                    const funds = result.data.map((fund, index) => {
                        const sortedReturnValues = fund.monthly_returns.sort((a, b) => (new Date(a.date)) - (new Date(b.date))).map(r => r.return)
                        const noReturns = sortedReturnValues.length
                        const annualReturn = ((growth(sortedReturnValues) ** (12 / sortedReturnValues.length) - 1) * 100).toFixed(1)
                        let trending = 3
                        if (fund.trending === 'medium') {
                            trending = 2
                        }
                        if (fund.trending === 'high') {
                            trending = 1
                        }
                        return Object.assign(fund, {
                            trending,
                            show: fund.fund_tags.map(tag => tag.name).some(tag => activeFilters.includes(tag)),
                            fundTags: fund.fund_tags.map(tag => tag.name),
                            annualReturn: annualReturn,
                            annualStd: stdev(sortedReturnValues).toFixed(1),
                            twelveMonthReturn: noReturns >= 12 ? ((growth(sortedReturnValues.slice(noReturns - 12, noReturns)) - 1) * 100).toFixed(1) : 'N/A',
                            sixMonthReturn: noReturns >= 6 ? ((growth(sortedReturnValues.slice(noReturns - 6, noReturns)) - 1) * 100).toFixed(1) : 'N/A',
                            threeMonthReturn: noReturns >= 3 ? ((growth(sortedReturnValues.slice(noReturns - 3, noReturns)) - 1) * 100).toFixed(1) : 'N/A',
                        })
                    })
                    setData(result.data)
                    ref.current.showSpinner(false)
                    const actionBody = {
                        action_type: 'visit',
                        description: 'all funds',
                        fund: null,
                        user: localStorage.getItem('user-email')
                    }
                    return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                })
                .then(result => {})
                .catch(err => {
                    ref.current.showSpinner(false)
                    const loc = window.location
                    if (!err.response) {
                        setData(JSON.parse(localStorage.getItem('funds')))
                        ref.current.showOffline(true)
                        return 'offline'
                    }
                    if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                })
        } else {
            setData(JSON.parse(localStorage.getItem('funds')))
            ref.current.showSpinner(false)
        }
    }, []) 
    
    const rows = data.length > 0 ? data.filter(fund => fund.show).map(fund => buildRow(fund)) : []
    const navigate = params => {
        const loc = window.location
        return window.location.href = `${loc.protocol}//${loc.host}/funds/${params.row.id}`
    }

    const updateFilter = e => {
        let cache = activeFilters
        if (e.target.checked) {
            cache = activeFilters.concat(e.target.value)
            setActiveFilters(cache)
        } else {
            cache = activeFilters.filter(tag => tag !== e.target.value)
            setActiveFilters(cache)
        }
        setData(data.map(fund => {
            fund.show = fund.fundTags.some(tag => cache.includes(tag))
            return fund
        }))
    }

    const classes = fundListStyles()
    const suggestedFunds = data.filter(fund => fund.suggested)
    const connectedFunds = data.filter(fund => fund.connected)
    const remainingFunds = data.filter(fund => !fund.suggested && !fund.connected)
    return (
      <div className={classes.fundContainer}>
          <CommonContainer ref={ref} section='all_funds'>
              <Paper elevation={0} className={classes.fundPaper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h2" align="left" gutterBottom className={classes.title}>HedgeVista Funds</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {
                            filters.map(filter => 
                                <Fragment key={filter.category}>
                                   <List>
                                        <ListItem key={filter.category} sx={{ paddingLeft: 0 }}>
                                            <ListItemIcon sx={{ minWidth: 32 }}>
                                                <IconButton
                                                    color={filterCategories.find(c => c.category === filter.category).show ? 'primary': 'inherit'}
                                                    onClick={toggleCategory}
                                                    value={filter.category}
                                                >
                                                    {filter.icon}
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText sx={{ fontWeight: 'bold' }} primary={filter.category} 
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                }} 
                                            />
                                        </ListItem>
                                    </List> 
                                    <FormGroup sx={{ paddingLeft: 2 }}>
                                        {filter.tags.map(tag => 
                                            <FormControlLabel
                                                 control={<Checkbox checked={activeFilters.includes(tag.value)} value={tag.value} onChange={updateFilter} />}
                                                 label={tag.label}
                                                 key={tag.label}
                                            />
                                        )}
                                    </FormGroup>
                                    <Divider />
                                </Fragment>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        {  data.length ? 
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    autoHeight={true}
                                    rows={rows}
                                    columns={columns}
                                    pageSize={40}
                                    rowsPerPageOptions={[40]}
                                    onRowClick={navigate}
                                    initialState={{
                                        sorting: {
                                          sortModel: [{ field: 'trending', sort: 'asc' }],
                                        },
                                    }}
                                />
                            </div>
                        : null }
                    </Grid>
                </Grid>
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
          </CommonContainer>
      </div>
    )
}