import React from 'react'
import Link from '@mui/material/Link'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { navBarStyles } from '../style/ComponentStyles'
import PublicMenu from './PublicMenu'
import PrivateMenu from './PrivateMenu'


export default function NavBar(props) {
    const classes = navBarStyles()
    return(
        <div className={classes.wrapper}>
            <AppBar className={classes.topAppBar}>
                <Toolbar>
                    <Link href={ !localStorage.getItem('token') ? '/' : '/home'} color="inherit">
                        <img src="/hv_logo.png" alt="HedgeVista" className={classes.navBarLogo} />
                    </Link>
                    <div className={classes.grow} />
                    { !localStorage.getItem('token') ? <PublicMenu /> : <PrivateMenu /> }
                </Toolbar>
            </AppBar>
        </div>
    )
}