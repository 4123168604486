import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import ListIcon from '@mui/icons-material/List'
import HomeIcon from '@mui/icons-material/Home'
import ChatIcon from '@mui/icons-material/Chat'
import { mobileBottomNavStyles } from '../style/ComponentStyles'


export default function MobileBottomNav(props) {
  const section = {
    'home': 0,
    'all_funds': 1,
    'contact': 2
  }
  const [value, setValue] = React.useState(section[props.section])
  const classes = mobileBottomNavStyles()

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction href='/home' label='Home' icon={<HomeIcon />} />
      <BottomNavigationAction href='/funds' label='All Funds' icon={<ListIcon />} />
      <BottomNavigationAction href='/contact' label='Chat' icon={<ChatIcon />} />
    </BottomNavigation>
  )
}