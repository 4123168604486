import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { bottomNavBarStyles } from '../style/ComponentStyles'

export default function BottomNavBar(props) {
    const isWideDisplay = useMediaQuery('(min-width:800px)')
    const classes = bottomNavBarStyles(isWideDisplay)
    return(
        <AppBar position="fixed" color="secondary" className={classes.bottomAppBar}>
            <Toolbar>
                Powered By HedgeVista Copyright © {(new Date()).getFullYear()}
                <Typography variant="h6" className={classes.title} />
                { !localStorage.getItem('token') ? null :
                    <Typography className={classes.root}>
                        <Link href="/user-agreement" color="inherit">User Agreement</Link>
                        <Link href="/privacy" color="inherit">Privacy Policy</Link>
                        <Link href="/terms" color="inherit">Terms and Conditions</Link>
                        <Link href="/careers" color="inherit">Careers</Link>
                        <Link href="/contact" color="inherit">Contact Us</Link>
                    </Typography>
                }
            </Toolbar>
        </AppBar>
    )
}
