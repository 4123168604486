import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import axios from 'axios'
import { fundListStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { useParams } from "react-router-dom"
import FundCards from '../../components/FundCards'
import { api_root } from '../../config'


export default function Tag(props) {
    let { tag } = useParams()
    tag = tag ? tag : 'No tag submitted'
    const ref = useRef(null)
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        ref.current.showSpinner(true)
        if (navigator.onLine) {
            axios
                .get(`${api_root}/hash-tags/${tag}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                        setData(result.data)
                        ref.current.showSpinner(false)
                        setLoaded(true)
                        const actionBody = {
                            action_type: 'tagView',
                            description: `#${tag}`,
                            fund: null,
                            user: localStorage.getItem('user-email')
                        }
                        return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                    })
                    .then(result => {})
                    .catch(err => {
                        ref.current.showSpinner(false)
                        setLoaded(true)
                        const loc = window.location
                        if (!err.response) {
                            ref.current.showOffline(true)
                            setData(JSON.parse(localStorage.getItem('tags')).filter(t => t.name.split('#')[1] === tag)[0])
                            return 'offline'
                        }
                        if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                    })
        } else {
            setData(JSON.parse(localStorage.getItem('tags')).filter(t => t.name.split('#')[1] === tag)[0])
            ref.current.showSpinner(false)
            setLoaded(true)
        }
    }, [])
    const classes = fundListStyles()
    return (
      <div className={classes.fundContainer}>
          <CommonContainer ref={ref}>
              <Paper elevation={0} className={loaded ? classes.fundPaper : classes.longPaper}>
                { data && data.funds_tagged && data.funds_tagged.length ? <FundCards title={`Funds for #${tag}`} funds={data.funds_tagged} /> : 
                    <Card className={classes.longCard}>
                        <CardContent>
                            <Typography variant="h4" align="center" gutterBottom>
                                { loaded ? 'No Funds' : 'Loading...' }
                            </Typography>
                            <div>
                                { loaded ? <p>No funds for this tag were found.</p> : null }
                            </div>
                        </CardContent>
                    </Card>
                }
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
          </CommonContainer>
      </div>
    )
}