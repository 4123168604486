import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import { fundListStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


export default function TagList(props) {
    const ref = useRef(null)
    const [data, setData] = useState([])
    useEffect(() => {
        ref.current.showSpinner(true)
        if (navigator.onLine) {
            axios
                .get(`${api_root}/hash-tags`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                    setData(result.data.sort((a, b) => {
                        if (a.name < b.name) return -1
                        if (a.name > b.name) return 1
                        return 0
                    }).filter(t => t.name.indexOf('#') === 0))
                    ref.current.showSpinner(false)
                    const actionBody = {
                        action_type: 'visit',
                        description: 'all tags',
                        fund: null,
                        user: localStorage.getItem('user-email')
                    }
                    return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                })
                .then(result => {})
                .catch(err => {
                    ref.current.showSpinner(false)
                    const loc = window.location
                    if (!err.resposne) {
                        ref.current.showOffline(true)
                        return setData(JSON.parse(localStorage.getItem('tags')))
                    }
                    if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                    if (err.response.status === 404) window.location.href = loc.protocol + "//" + loc.host + '/tags'
                })
        } else {
            setData(JSON.parse(localStorage.getItem('tags')))
            ref.current.showSpinner(false)
        }
    }, [])
    const classes = fundListStyles()
    return (
      <div className={classes.fundContainer}>
          <CommonContainer ref={ref} section='all_tags'>
              <Paper elevation={0} className={classes.longPaper}>
                <Grid container>
                        <Grid item xs={12} className={classes.fundItem}>
                            <Typography variant="h4" align="center" gutterBottom>
                                All Tags
                            </Typography>
                            { data.length ?
                                <Card className={classes.longCard}>
                                    <CardContent>
                                        <ul>
                                        { data.map(tag => <li key={tag.name}><Link href={`/tags/${tag.name.split('#')[1]}`} color="primary">{tag.name}</Link> {`(${tag.funds_tagged.length} fund${tag.funds_tagged.length > 1 ? 's' : ''} with this tag)`}</li>) }
                                        </ul>
                                    </CardContent>
                                </Card> : null
                            }
                        </Grid>
                    </Grid>
                </Paper>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
          </CommonContainer>
      </div>
    )
}