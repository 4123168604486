import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import axios from 'axios'
import { fundListStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { useParams } from "react-router-dom"
import FundCards from '../../components/FundCards'
import { api_root } from '../../config'


export default function SearchResults(props) {
    let { search } = useParams()
    search = search ? search : 'No search term submitted'
    const ref = useRef(null)
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        ref.current.showSpinner(true)
        if (navigator.onLine) {
            axios
                .get(`${api_root}/funds`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                    setData(result.data)
                    ref.current.showSpinner(false)
                    setLoaded(true)
                    const actionBody = {
                        action_type: 'search',
                        description: search,
                        fund: null,
                        user: localStorage.getItem('user-email')
                    }
                    return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                })
                .then(result => {})
                .catch(err => {
                    ref.current.showSpinner(false)
                    setLoaded(true)
                    const loc = window.location
                    if (!err.resposne) {
                        ref.current.showOffline(true)
                        setData(JSON.parse(localStorage.getItem('funds')))
                        return 'offline'
                    }
                    if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                })
        } else {
            setData(JSON.parse(localStorage.getItem('funds')))
            ref.current.showSpinner(false)
            setLoaded(true)
        }
    }, [])
    const classes = fundListStyles()
    const results = data.filter(fund => (
        fund.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        fund.short_description.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        fund.fund_tags.reduce((a, c) => `${a} ${c.name.split('#')[1]}`, '').toLowerCase().indexOf(search.toLowerCase()) >= 0
    ))
    return (
      <div className={classes.fundContainer}>
          <CommonContainer ref={ref}>
              <Paper elevation={0} className={loaded && results.length ? classes.fundPaper : classes.longPaper}>
                { results.length ? <FundCards title={`Search Results for ${search}`} funds={results} /> : 
                    <Card className={classes.longCard}>
                        <CardContent>
                            <Typography variant="h4" align="center" gutterBottom>
                                { loaded ? 'No Results' : 'Searching...' }
                            </Typography>
                            <div>
                                { loaded ? <p>{ search === 'No search term submitted' ? search : `No results were found for "${search}".`}</p> : null }
                            </div>
                        </CardContent>
                    </Card>
                }
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
          </CommonContainer>
      </div>
    )
}