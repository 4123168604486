import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { careersStyles } from '../../style/ContainerStyles'
import Link from '@mui/material/Link'
import CommonContainer from '../common/CommonContainer'


export default function CareersResearchAnalyst() {
    const classes = careersStyles(true)
    return (
        <div className={classes.careersContainer}>
            <CommonContainer>
                <Paper elevation={0} className={classes.careersPaper}>
                    <Card className={classes.careersCard}>
                        <CardContent>
                            <Typography variant="h4" align="center" gutterBottom>
                            Research Associate / Research Analyst - Hedge Fund Industry Research
                            </Typography>
                            <p>
                            You will help building and growing our allocator user base for a fast growing digital financial service platform in Asia. You are interested in both finance industry and research, able to adapt quickly, love working in fast-paced financial industry environments.
                            </p>
                            <h3>Your Responsibilities</h3>
                            <p>
                            We believe that internships are key to build impactful learning experiences. You will be working closely with the CEO, core client service team to acquire and maintain key user data points, increase user engagements and satisfaction. This is a great opportunity for candidates eager to learn fast and to improve a diverse set of skills in a startup.
                            </p>
                            <ul>
                                <li>Conduct first hand and secondary research on global allocators and develop a understanding of their investment mandate and global investment trends.</li>
                                <li>Provide weekly executive summary of engagement logs and new user updates.</li>
                                <li>Support client service team to drive user engagement and satisfaction and maintain our internal CRM database.</li>
                                <li>Maintain client service calendar and ensure it's sync with research agenda and other content publish agenda.</li>
                            </ul>
                            <h3>Desired skills and experience</h3>
                            <ul>
                                <li>Graduate in or pursuing a bachelor’s degree in Business, Marketing, Technology or Finance related disciplines.</li>
                                <li>Proactive, high autonomy, result-driven, creative, solution-oriented</li>
                                <li>Ability to learn and adapt fast, passion for entrepreneurship</li>
                                <li>Excellent English writing and communication skills</li>
                                <li>Excellent attention to details, time management skills, ability to multitask and prioritize work</li>
                                <li>Great Excel and database management skills.</li>
                                <li>Previous experience in finance industry or start-up is a plus.</li>
                            </ul>
                            <h3>What we can provide</h3>
                            <ul>
                                <li>Stimulating startup environment in fast pace finance industry</li>
                                <li>Coaching from an experienced team</li>
                                <li>Impactful learning experience for your career</li>
                            </ul>
                            <h3>Location</h3>
                            <ul>
                                <li>Central, Hong Kong</li>
                            </ul>
                            <h3>Duration</h3>
                            <ul>
                                <li>Start in March 2021</li>
                            </ul>
                            <h3>Salary Range</h3>
                            <ul>
                                <li>This is a paid internship upon satisfactory completion of first month.  Salary depends on experience. Opportunity to transit to permanent position.</li>
                            </ul>
                            <h3>How to Apply</h3>
                            <ul>
                                <li>send your CV to <Link href="mailto:lili@hedgevista.com?subject=RA2021" target="_blank" color="primary">lili@hedgevista.com</Link> with a short paragraph about yourself. Please quote “RA2021” in the subject line</li>
                            </ul>
                        </CardContent>
                    </Card>
                </Paper>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </CommonContainer>
        </div>
    )
}