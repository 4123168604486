import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Theme from './Theme'


const theme = Theme()


const navBarStyles = makeStyles(theme => ({
    root: {
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
    },
    wrapper: {
        flexGrow: 1,
    },
    topAppBar: {
        backgroundColor: '#ffffff',
        color: '#4a4a4a',
    },
    navBarLogo: {
        width: '140px',
        verticalAlign: 'middle',
    },
    grow: {
      flexGrow: 1,
      textAlign: 'right',
    },
    search: {
        display: 'inline-block',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        '&:hover': {
          backgroundColor: alpha(theme.palette.secondary.light, 0.05),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0) + ' !important',
        paddingLeft: `calc(1em + ${theme.spacing(4)}) !important`,
        transition: theme.transitions.create('width'),
        width: '12ch !important',
        [theme.breakpoints.up('sm')]: {
          width: '12ch !important',
          '&:focus': {
            width: '20ch !important',
          },
        },
      },
      sectionDesktop: {
        marginLeft: theme.spacing(1),
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
        },
      },
      listbox: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '26ch !important',
        }
      },
      popper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '24ch !important',
        }
      },
}))

const bottomNavBarStyles = isWideDisplay => {
    return makeStyles(theme => ({
        root: {
            display: isWideDisplay ? 'block' : 'none',
            '& > * + *': {
            marginLeft: theme.spacing(2),
            },
        },
        bottomAppBar: {
            top: 'auto',
            bottom: 0,
            backgroundColor: '#ffffff',
            color: '#4a4a4a',
        },
        title: {
        flexGrow: 1,
        },
    }))()
}

const mobileBottomNavStyles = isWideDisplay => {
  return makeStyles(theme => ({
      root: {
          position: 'fixed',
          bottom: 0,
          width: '100%',
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      },
  }))()
}

const fundCardsStyles = makeStyles(() => ({
    action: {
        height: '100%',
    },
    root: {
        height: '100%',
        position: 'relative',
        marginLeft: 'auto',
        overflow: 'initial',
        background: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
      },
      media: {
        marginLeft: 0,
        marginTop: theme.spacing(2),
        width: '100%',
        height: 0,
        minHeight: 60,
        backgroundColor: '#fff',
        position: 'relative',
        backgroundSize: 'contain !important',
        [theme.breakpoints.up('md')]: {
          width: '100%',
          minWidth: 100,
          maxWidth: 100,
          marginTop: 0,
          marginLeft: theme.spacing(2),
        },
      },
      iconContainer: {
        marginLeft: 0,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        width: 60,
        height: 0,
        minHeight: 60,
        backgroundColor: 'transparent',
        position: 'relative',
        backgroundSize: 'contain !important',
        [theme.breakpoints.up('md')]: {
          width: '100%',
          minWidth: 60,
          maxWidth: 60,
          marginTop: 0,
          marginLeft: theme.spacing(2),
        },
      },
      icon: {
          fontSize: "3rem !important",
          color: theme.palette.primary.main,
      },
      title: {
          marginTop: theme.spacing(3),
      }
}))

const modalStyles = makeStyles(() => ({
      modal: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        marginTop: '-160px',
        [theme.breakpoints.up('md')]: {
          maxWidth: 400,
          marginLeft: '-200px',
          left: '50%',
        },
    },
    button: {
        margin: 'auto',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
    },
}))

export {
    navBarStyles,
    bottomNavBarStyles,
    mobileBottomNavStyles,
    fundCardsStyles,
    modalStyles,
}