import 'typeface-roboto';
import React, { Fragment } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
  useLocation
} from "react-router-dom";
import axios from 'axios'
import Typography from '@mui/material/Typography'
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Home from './containers/public/Home'
import Legal from './containers/public/Legal'
import Login from './containers/public/Login'
import Contact from './containers/public/Contact'
import Careers from './containers/public/Careers'
import LandingPage from './containers/public/LandingPage'
import CareersResearchAnalyst from './containers/public/CareersResearchAnalyst'
import CareersMarketingExecutive from './containers/public/CareersMarketingExecutive'
import PrivateHome from './containers/private/PrivateHome'
import FundList from './containers/private/FundList'
import PortfolioList from './containers/private/PortfolioList'
import TagList from './containers/private/TagList'
import Tag from './containers/private/Tag'
import ConferenceList from './containers/private/ConferenceList'
import Conference from './containers/private/Conference'
import PodcastList from './containers/private/PodcastList'
import PodcastEpisode from './containers/private/PodcastEpisode'
import RecommendedFundList from './containers/private/RecommendedFundList'
import Fund from './containers/private/Fund'
import Portfolio from './containers/private/Portfolio'
import SearchResults from './containers/private/SearchResults'
import Newsletter from './containers/private/Newsletter'
import PDF from './containers/private/PDF'
import AgreementModal from './components/AgreementModal'
import { api_root } from './config'

const theme = createTheme();

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Typography component="div">
          <Router>
              <Routes>
                <Route exact path="/" element={ <Home /> } />
                <Route path="/privacy" element={ <Legal url={`${api_root}/public-contents/2`} /> } />
                <Route path="/terms" element={ <Legal url={`${api_root}/public-contents/3`} /> } />
                <Route path="/user-agreement" element={ <Legal url={`${api_root}/public-contents/1`} /> } />
                <Route path="/login" element= { <Login /> } />
                <Route path="/landing/:slug" element={ <LandingPage /> } />
                <Route path="/landing" element={ <Home /> } />
                <Route path="/contact" element={ <Contact /> } />
                <Route path="/request-access" element={ <Contact subject="Account request" /> } />
                <Route path="/careers/research-analyst" element={ <CareersResearchAnalyst /> } />
                <Route path="/careers/marketing-executive" element={ <CareersMarketingExecutive /> } />
                <Route path="/careers" element={ <Careers /> } />
                <Route path="/search/:search" element={ <PrivateRoute><SearchResults /></PrivateRoute> } />
                <Route path="/search" element={ <PrivateRoute><SearchResults /></PrivateRoute> } />
                <Route path="/tags/:tag" element={ <PrivateRoute><Tag /></PrivateRoute> } />
                <Route path="/tags" element={ <PrivateRoute><TagList /></PrivateRoute> } />
                <Route path="/conferences/:conference" element={ <PrivateRoute><Conference /></PrivateRoute> } />
                <Route path="/conferences" element={ <PrivateRoute><ConferenceList /></PrivateRoute> } />
                <Route path="/podcast/:guid" element={ <PrivateRoute><PodcastEpisode /></PrivateRoute> } />
                <Route path="/podcast" element={ <PrivateRoute><PodcastList /></PrivateRoute> } />
                <Route path="/funds/recommended" element={ <PrivateRoute><RecommendedFundList /></PrivateRoute> } />
                <Route path="/funds/:slug" element={ <PrivateRoute><Fund /></PrivateRoute> } />
                <Route path="/funds" element={ <PrivateRoute><FundList /></PrivateRoute> } />
                <Route path="/portfolios/:slug" element={ <PrivateRoute><Portfolio /></PrivateRoute> } />
                <Route path="/portfolios" element={ <PrivateRoute><PortfolioList /></PrivateRoute> } />
                <Route path="/newsletters/:guid" element={ <PrivateRoute><Newsletter /></PrivateRoute> } />
                <Route path="/pdf/:guid" element={ <PrivateRoute><PDF /></PrivateRoute> } />
                <Route path="/home" element={ <PrivateRoute><PrivateHome /></PrivateRoute> } />
              </Routes>
          </Router>
        </Typography>
    </ThemeProvider>
    </StyledEngineProvider>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PrivateRoute({ children, ...rest }) {
  const loc = window.location
  const guid = useQuery().get('g')
  const accepted_terms = localStorage.getItem('accepted_terms')
  const wrappedChildren = <Fragment>
    { isAuthenticated() && !accepted_terms ? <AgreementModal /> : null }
    { children }
  </Fragment>
  if (guid) {
    if (!localStorage.getItem('token')) {
      axios.post(`${api_root}/auth/local`, { provider: 'passwordless', identifier: guid })
      .then(res => {
        localStorage.setItem('token', res.data.jwt)
        localStorage.setItem('user-name', res.data.user.username)
        localStorage.setItem('user-email', res.data.user.email)
        window.location.href = loc.protocol + "//" + loc.host + loc.pathname //rest.path
      })
      .catch(err => {
        window.location.href = loc.protocol + "//" + loc.host + rest.path
      })
      return (
        null //<Route {...rest} render={({ location }) => (<Navigate to={{ pathname: "/login" }} />)} />
      )
    }
    window.location.href = loc.protocol + "//" + loc.host + loc.pathname //rest.path
    return (
      null //<Route {...rest} render={({ location }) => (<Navigate to={{ pathname: rest.path }} />)} />
    )
  } else {
    return isAuthenticated() ? (wrappedChildren) : (<Navigate to={{ pathname: "/login" }} />)/*(
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated() ? (
            wrappedChildren
          ) : (
            <Navigate
              to={{ pathname: "/login" }}
            />
          )
        }
      />
    )*/
  }
}

function isAuthenticated() {
  return localStorage.getItem('token') != null
}