import React, { useState, useEffect, useRef }  from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import showdown from 'showdown'
import axios from 'axios'
import CommonContainer from '../common/CommonContainer'
import { landingPageStyles } from '../../style/ContainerStyles'
import { useParams } from "react-router-dom"
import { api_root } from '../../config'


export default function LandingPage(props) {
    const { slug } = useParams()
    const isWideDisplay = useMediaQuery('(min-width:600px)')
    const classes = landingPageStyles(isWideDisplay)
    const ref = useRef(null)
    const [data, setData] = useState([])
    const [status, setStatus] = useState('loading')
    const subject = 'Landing Page Conversion'
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [permission, setPermission] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [usedExcludedEmail, setUsedExcludedEmail] = useState(false)
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const handleChange = (event) => setPermission(!permission)
    const excludedEmailDomains = ['@gmail', '@hotmail', '@yahoo']
    const includesAny = (str, test) => test.reduce((a, c) => a = a || str.toLowerCase().includes(c), false)
    const sendMessage = () => {
        setShowErrors(false)
        setUsedExcludedEmail(false)
        if (includesAny(email, excludedEmailDomains)) {
            setEmail('')
            setUsedExcludedEmail(true)
            setStatus('ready')
            return setShowErrors(true)
        }
        if (name === '' || email === '' || !emailRegExp.test(email) || permission === false) {
            if (!emailRegExp.test(email)) setEmail('')
            setStatus('ready')
            return setShowErrors(true)
        }
        ref.current.showSpinner(true)
        setStatus('sending')
        const message = `A request has been submitted on the ${data.title} landing page by ${name} (${email}) ${phone !== '' ? 'Phone: ' + phone : ''}`
        axios
            .post(`${api_root}/messages`, { subject, message, name, email })
            .then(result => {
                setStatus('sent')
                setName('')
                setEmail('')
                ref.current.showSpinner(false)
            })
            .catch(err => {
                setStatus('error')
                ref.current.showSpinner(false)
            })
    }
    useEffect(() => {
        if (navigator.onLine) {
            axios
                .get(`${api_root}/landing-pages/${slug}`)
                .then(result => {
                    setData(result.data)
                    setStatus('ready')
                })
                .then(result => {})
                .catch(err => {
                    const loc = window.location
                    if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                    if (err.response.status === 404) {
                        return window.location.href = loc.protocol + "//" + loc.host
                    }
                    setStatus('error')
                })
        } else {
            ref.current.showSpinner(false)
            setStatus('ready')
        }
    }, [])
    const converter = new showdown.Converter()
    return (
      <div className={classes.homeHeroContainer}>
        <CommonContainer ref={ref}>
          { status === 'loading' ? null :
            <Paper elevation={0} className={classes.homeHero}>
                <Typography component="div" color="secondary">
                    <h1 color="secondary" className={classes.homeHeroH1}>{data.title}</h1>
                </Typography>
                <h2 className={classes.homeSubtitle}>{data.subtitle}</h2>
                <Grid container spacing={3} className={classes.features}>
                   <Grid item xs={12} sm={6}>
                        <div dangerouslySetInnerHTML={{__html: converter.makeHtml(data.description)}} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.contactCard}>
                            <CardContent>
                                <Typography variant="h4" gutterBottom>{data.form_title}</Typography>
                                { status === 'error' ? <Typography color="error">Something went wrong. Please try again.</Typography> : null }
                                { status === 'sent' ? <Typography color="primary">Thank you. Your request has been submitted.</Typography> : null }
                                <div className={status === 'sent' ? classes.hidden : classes.visible }>
                                    <Typography gutterBottom dangerouslySetInnerHTML={{__html: converter.makeHtml(data.form_description)}} />
                                    <form noValidate autoComplete="on">
                                        <FormControl fullWidth className={classes.formControl}>
                                            <TextField
                                                id="name"
                                                label="Full Name"
                                                error={showErrors && name === ''}
                                                helperText={showErrors && name === '' ? 'Your name is required' : null}
                                                onChange={() => setName(document.getElementById('name').value)}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <TextField
                                                id="email"
                                                label="Email Address"
                                                error={showErrors && email === ''}
                                                helperText={showErrors && email === '' ? usedExcludedEmail ? 'Please use a company email. Personal email addresses can not be accepted' : 'A valid email address is required' : null}
                                                onChange={() => setEmail(document.getElementById('email').value)}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <TextField
                                                id="phone"
                                                label="Phone Number"
                                                onChange={() => setPhone(document.getElementById('phone').value)}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className={classes.formControl} error={showErrors && permission === false}>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={permission}
                                                    onChange={handleChange}
                                                    name="permission"
                                                    color="primary"
                                                />
                                                }
                                                label="HedgeVista can email me."
                                            /> 
                                            <FormHelperText className={showErrors && permission === false ? classes.visible : classes.hidden }>Permission to contact you is required.</FormHelperText>
                                        </FormControl>

                                   </form>
                                </div>
                            </CardContent>
                            <CardActions className={classes.action}>
                                <div className={status === 'sent' ? classes.hidden : classes.visible }>
                                    <Button variant="contained" color="primary" className={classes.button} onClick={sendMessage}>{data.submit_button_text}</Button>
                                </div>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
          }
        </CommonContainer>
      </div>
    )
}