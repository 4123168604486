import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { careersStyles } from '../../style/ContainerStyles'
import Link from '@mui/material/Link'
import CommonContainer from '../common/CommonContainer'


export default function CareersMarketingExecutive() {
    const classes = careersStyles(true)
    return (
        <div className={classes.careersContainer}>
            <CommonContainer>
                <Paper elevation={0} className={classes.careersPaper}>
                    <Card className={classes.careersCard}>
                        <CardContent>
                            <Typography variant="h4" align="center" gutterBottom>
                                Marketing Executive
                            </Typography>
                            <p>
                            You will drive and implement the content strategy for a fast growing digital financial service platform in Asia. You are interested in both finance industry and digital marketing, able to adapt quickly, love working in fast-paced financial industry environments, and have excellent English skills.
                            </p>
                            <h3>Your Responsibilities</h3>
                            <p>
                            We believe that internships are key to build impactful learning experiences. You will be working closely with the CEO, core research team and client service team to drive platform traffics, user engagements and public awareness of platform.  This is a great opportunity for candidates eager to learn fast and to improve a diverse set of skills in a startup.
                            </p>
                            <ul>
                                <li>Leverage internal IP and research to develop digital content (i.e podcasts, infographics, newsletters, web conferences) to drive user engagement and platform traffics.</li>
                                <li>Organize and drive attendance of virtual web conference through internal and public network, with the aim of growing the attendee number and impact level over time. This includes producing marketing materials pre-and post conference, answering pre-event inquiries and attendee follow up. </li>
                                <li>Implement direct marketing plan to targeted group of platform users, by developing targeted email content on a weekly basis to increase user satisfaction and conversion rate.</li>
                                <li>Maintain various campaign calendar and marketing database. Provide weekly executive summary of digital marketing performance.</li>
                                <li>Develop templates for newsletter, social media tiles and external presentation materials.</li>
                            </ul>
                            <h3>Desired skills and experience</h3>
                            <ul>
                                <li>Graduate in or pursuing a bachelor’s degree in Business, Marketing, Technology or Journalism related disciplines.</li>
                                <li>Proactive, high autonomy, result-driven, creative, solution-oriented</li>
                                <li>Ability to learn and adapt fast, passion for entrepreneurship</li>
                                <li>Excellent time management skills, ability to multitask and prioritize work</li>
                                <li>Excellent English writing and oral communication skills</li>
                                <li>Basic Excel and Powerpoint skills are required. Photoshop and Illustrator skills are good to have but not essential.</li>
                                <li>Previous experience in audio/visual editing, marketing collateral creation is a plus.</li>
                                <li>Previous experience in finance industry or start-up is a plus.</li>
                            </ul>
                            <h3>What we can provide</h3>
                            <ul>
                                <li>Stimulating startup environment</li>
                                <li>Coaching from an experienced team</li>
                                <li>Impactful learning experience for your career</li>
                            </ul>
                            <h3>Location</h3>
                            <ul>
                                <li>Central, Hong Kong</li>
                            </ul>
                            <h3>Duration</h3>
                            <ul>
                                <li>Start in March 2021. 6 months</li>
                            </ul>
                            <h3>Salary Range</h3>
                            <ul>
                                <li>This is a paid internship upon satisfactory completion of first month.  Salary depends on experience. Opportunity to transit to permanent position.</li>
                            </ul>
                            <h3>How to Apply</h3>
                            <ul>
                                <li>send your CV to <Link href="mailto:lili@hedgevista.com?subject=CME2021" target="_blank" color="primary">lili@hedgevista.com</Link> with a short paragraph about yourself. Please quote “CME2021” in the subject line</li>
                            </ul>
                        </CardContent>
                    </Card>
                </Paper>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </CommonContainer>
        </div>
    )
}