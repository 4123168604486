import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import axios from 'axios'
import { fundListStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import FundCards from '../../components/FundCards'
import { api_root } from '../../config'


export default function RecommendedFundList(props) {
    const ref = useRef(null)
    const [data, setData] = useState([])
    useEffect(() => {
        ref.current.showSpinner(true)
        if (navigator.onLine) {
            axios
                .get(`${api_root}/funds`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                    setData(result.data)
                    ref.current.showSpinner(false)
                    const actionBody = {
                        action_type: 'visit',
                        description: 'recommended funds',
                        fund: null,
                        user: localStorage.getItem('user-email')
                    }
                    return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                })
                .then(result => {})
                .catch(err => {
                    const loc = window.location
                    if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                    ref.current.showSpinner(false)
                })
        } else {
            setData(JSON.parse(localStorage.getItem('funds')))
            ref.current.showSpinner(false)
        }
    }, [])
    const classes = fundListStyles()
    const suggestedFunds = data.filter(fund => fund.suggested)
    return (
      <div className={classes.fundContainer}>
          <CommonContainer ref={ref} section='recommended'>
              <Paper elevation={0} className={suggestedFunds.length ? classes.fundPaper : classes.longPaper}>
                { suggestedFunds.length ? <FundCards title='Recommended Funds' funds={suggestedFunds} /> : 
                    <Card className={classes.longCard}>
                        <CardContent>
                            <Typography variant="h4" align="center" gutterBottom>
                                No Recommendations
                            </Typography>
                            <div>
                                <p>We don't have any funds to recommend for you at the moment.</p>
                                <p><Link href="/funds" color="primary">Click here to browse our list of funds.</Link></p>
                            </div>
                        </CardContent>
                    </Card>
                }
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
          </CommonContainer>
      </div>
    )
}