import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import BalanceIcon from '@mui/icons-material/Balance'
import SpeedIcon from '@mui/icons-material/Speed'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import PersonIcon from '@mui/icons-material/Person'
import { fundCardsStyles } from '../style/ComponentStyles'
import { api_root } from '../config'


export default function PortfolioCards(props) {
    const classes = fundCardsStyles()
    const icons = {
        'aggressive': <SpeedIcon className={classes.icon} />,
        'balanced': <BalanceIcon className={classes.icon} />,
        'conservative': <AccountBalanceIcon className={classes.icon} />
    }
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" align="left" gutterBottom className={classes.title}>HedgeVista Portfolios</Typography>
            </Grid>
                {
                    props.portfolios.length ?
                    props.portfolios.map(portfolio => 
                        <Grid item xs={12} sm={4} key={portfolio.slug}>
                            <CardActionArea className={classes.action} href={`/portfolios/${portfolio.slug}`}>
                                <Card className={classes.root}>
                                    <div className={classes.iconContainer}>
                                        {icons[portfolio.slug]}
                                    </div>
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            {portfolio.name} Portfolio
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                    ) : null
                }
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" align="left" gutterBottom className={classes.title}>
                    Personalised for {localStorage.getItem('user-name') == null ? 'You' : localStorage.getItem('user-name')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <CardActionArea className={classes.action} href="/portfolios/personalised">
                    <Card className={classes.root}>
                        <div className={classes.iconContainer}>
                            <PersonIcon className={classes.icon} />
                        </div>
                        <CardContent>
                            <Typography variant="h5" component="h2" align="left" gutterBottom>
                                Personalised Portfolio
                            </Typography>
                        </CardContent>
                    </Card>
                </CardActionArea>
            </Grid>
        </Grid>
    )
}