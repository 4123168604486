import React from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import CommonContainer from '../common/CommonContainer'
import { homeStyles } from '../../style/ContainerStyles'


export default function Home() {
    const isWideDisplay = useMediaQuery('(min-width:600px)')
    const classes = homeStyles(isWideDisplay)
    return (
      <div className={classes.homeHeroContainer}>
        <CommonContainer>
            <Paper elevation={0} className={classes.homeHero}>
                <Typography component="div" color="secondary">
                <h1 color="secondary" className={classes.homeHeroH1}>
                Creating Advanced Technology for Alternative Assets
                </h1>
                </Typography>
                <h2 className={classes.homeSubtitle}>
                HedgeVista is creating innovative and scalable digital solutions for the alternatives universe
                </h2>
                <Button variant="contained" color="primary" href="/request-access">Request Access</Button>
            </Paper>
        </CommonContainer>
      </div>
    )
}