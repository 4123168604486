import React, {useEffect, useState} from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'
import showdown from 'showdown'
import axios from 'axios'
import { longContentStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'


export default function Legal(props) {
    const [data, setData] = useState({})
    useEffect(() => {
        axios
          .get(props.url)
          .then(result => setData(result.data))
    }, [])
    const converter = new showdown.Converter()
    const classes = longContentStyles()
    return (
      <div className={classes.longContainer}>
          <CommonContainer>
              <Paper elevation={0} className={classes.longPaper}>
                  <Card className={classes.longCard}>
                      <CardContent>
                          <Typography variant="h4" align="center" gutterBottom>
                              {data.title}
                          </Typography>
                          <div dangerouslySetInnerHTML={{__html: converter.makeHtml(data.content)}} />
                      </CardContent>
                  </Card>
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
          </CommonContainer>
      </div>
    )
}