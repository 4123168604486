import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import showdown from 'showdown'
import axios from 'axios'
import { fundStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { useParams } from "react-router-dom"
import { api_root } from '../../config'


export default function Conference(props) {
    let { conference } = useParams()
    conference = conference ? conference : 'No conference submitted'
    const ref = useRef(null)
    const [data, setData] = useState([])
    const [status, setStatus] = useState('loading')
    const [attendingConferences] = useState(localStorage.getItem('attendingConferences') != null ? localStorage.getItem('attendingConferences').split('|') : '')
    const requestAttendence = () => {
        setStatus('sending')
        const subject = 'Conference Attendance Request'
        const name = localStorage.getItem('user-name')
        const email = localStorage.getItem('user-email')
        const message = `A request has been made by ${name} (${email}) to attend the ${data.title} conference. Please make the arrangements for this.`
        axios
            .post(`${api_root}/messages`, { subject, message, name, email })
            .then(result => {
                setStatus('sent')
                localStorage.setItem('attendingConferences', `${localStorage.getItem('attendingConferences')}|${data.slug}`)
                const actionBody = {
                    action_type: 'click',
                    description: 'conference request (request attendance button)',
                    fund: data.funds != null && data.funds.length === 1 ? data.funds[0].slug : null,
                    user: localStorage.getItem('user-email')
                }
                return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            })
            .then(result => {})
            .catch(err => {
                setStatus('error')
            })
    }
    useEffect(() => {
        if (navigator.onLine) {
            axios
                .get(`${api_root}/conferences/${conference}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                        setData(result.data)
                        setStatus('ready')
                        const actionBody = {
                            action_type: 'visit',
                            description: conference,
                            fund: result.data.funds != null && result.data.funds.length === 1 ? result.data.funds[0].slug : null,
                            user: localStorage.getItem('user-email')
                        }
                        return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                    })
                    .then(result => {})
                    .catch(err => {
                        const loc = window.location
                        if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                        setStatus('ready')
                    })
        } else {
            ref.current.showSpinner(false)
            setStatus('ready')
        }
    }, [])
    const converter = new showdown.Converter()
    const classes = fundStyles()
    return (
        <div className={classes.fundContainer}>
          { status !== 'loading' ?
          <CommonContainer>
              <Paper elevation={0} className={classes.fundPaper}>
                <Grid container>
                { data.message !== 'wrong user type' ?
                    <Grid item className={classes.fundItem}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Conference Details
                        </Typography>
                        <Card className={status === 'loading' ? classes.loading : classes.fundCard}>
                            <CardContent>
                                <h2>
                                    { data.title }
                                </h2>
                                <div dangerouslySetInnerHTML={{__html: converter.makeHtml(data.description)}} />
                                { data.funds != null ? data.funds.map(fund => (
                                  <div>
                                    <h2>
                                        { fund.name }
                                    </h2>
                                    <div dangerouslySetInnerHTML={{__html: converter.makeHtml(fund.short_description)}} />
                                    <div>
                                        <Link href={`/funds/${fund.slug}`}>Learn more about { fund.name }</Link>
                                    </div>
                                  </div>
                                )) : null }
                                <h2>
                                    Register to Attend
                                </h2>
                                <div>
                                    <p>
                                        {
                                            status === 'sent' || attendingConferences.includes(data.slug) ?
                                            'Your registration is being processed.' :
                                            'Click the button below to register for this conference.'
                                        }
                                    </p>
                                    <div className={classes.center}>
                                        <Button variant="contained" color={status === 'sent' ? 'secondary' : 'primary'} className={classes.button} onClick={requestAttendence} disabled={status === 'sending' || status === 'sent' || attendingConferences.includes(data.slug)}>
                                            {status === 'sent' || attendingConferences.includes(data.slug) ? 'Attendence Requested' : 'Register Interest'}
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid> :
                    <Grid item className={classes.fundItem}>
                        <Card className={status === 'loading' ? classes.loading : classes.fundCard}>
                            <CardContent>
                                <h2>Your account does not have permission to view this fund.</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                </Grid>
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
          </CommonContainer> : null }
              <Backdrop className={classes.backdrop} open={status === 'loading' ? true : false}>
                <CircularProgress color="inherit" disableShrink />
              </Backdrop>
      </div>
    )
}