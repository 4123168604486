import React, {useEffect, useState} from 'react'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { newsletterStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


export default function PDF(props) {
    const { guid } = useParams()
    const [status, setStatus] = useState('loading')
    const [data, setData] = useState({})
    useEffect(() => {
        setStatus('loading')
        axios
            .get(`${api_root}/newsletters/${guid}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(result => {
                setData(result.data)
                setStatus('ready')
            }).catch(err => {
                const loc = window.location
                if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                if (err.response.status === 404) window.location.href = loc.protocol + "//" + loc.host + '/funds'
                setStatus('ready')
            })
    }, [])
    const viewPDF = (source, element) => {
        const iframe = document.createElement('iframe')
        iframe.src = `/web/?file=${source}`
        iframe.width = '100%'
        iframe.height = '100%'
        iframe.style.border = 'none'
        element.style.height = '78vh'
        element.innerHTML = ''
        element.appendChild(iframe)
    }
    const classes = newsletterStyles()
    return (
      <div className={classes.newsletterContainer}>
          <CommonContainer>
              <Paper elevation={0} className={classes.newsletterPaper}>
                  <Card className={classes.newsletterCard}>
                      <CardContent>
                          <div id="viewer"></div>
                          {data.pdf ?
                             viewPDF(`${data.pdf.url}`, document.getElementById('viewer'))
                          : null}
                      </CardContent>
                  </Card>
              </Paper>
              <Backdrop className={classes.backdrop} open={status === 'loading' ? true : false}>
                <CircularProgress color="inherit" disableShrink />
              </Backdrop>
          </CommonContainer>
      </div>
    )
}