import makeStyles from '@mui/styles/makeStyles';
import Theme from './Theme'
import { grey } from '@mui/material/colors';


const theme = Theme()

const homeStyles = isWideDisplay => {
    return makeStyles(() => ({
        homeHeroContainer: {
            backgroundImage: 'url(/mesh_bg-1.png)',
            backgroundPositionX: `${isWideDisplay ? '30%' : '50%'}`,
            backgroundPositionY: `${isWideDisplay ? '-250px' : '0'}`,
            backgroundSize:`${isWideDisplay ? '300%' : '600%'}`,
            backgroundRepeat: 'no-repeat',
        },
        homeHero: {
            textAlign: 'center',
            paddingTop:  `${isWideDisplay ? '20vh' : '1vh'}`,
            backgroundColor: 'transparent',
        },
        homeHeroH1: {
            fontSize: '2.5em',
        },
        homeSubtitle: {
            marginBlockEnd: '1.66em',
        },
        features: {
            textAlign: 'center !important',
            padding: theme.spacing(2),
            paddingTop: `${isWideDisplay ? '30vh' : '7vh'}`,
            paddingBottom: '12vh',
        },
        featuresHeader: {
            paddingBottom: `${isWideDisplay ? '8vh !important' : '0'}`,
        },
        featuresTitle: {
            width: '100%',
        },
        featuresContent: {
            fontWeight: 'normal',
        },
        icon: {
            height: 60,
        }
    }))()
}

const landingPageStyles = isWideDisplay => {
    return makeStyles(() => ({
        homeHeroContainer: {
            backgroundImage: 'url(/mesh_bg-1.png)',
            backgroundPositionX: `${isWideDisplay ? '30%' : '40%'}`,
            backgroundPositionY: `${isWideDisplay ? '-250px' : '-220px'}`,
            backgroundSize:`${isWideDisplay ? '300%' : '600%'}`,
            backgroundRepeat: 'no-repeat',
        },
        homeHero: {
            textAlign: 'center',
            paddingTop:  '1vh',
            backgroundColor: 'transparent',
        },
        homeHeroH1: {
            fontSize: '2.5em',
        },
        homeSubtitle: {
            marginBlockEnd: '1.66em',
        },
        features: {
            textAlign: 'left !important',
            padding: theme.spacing(2),
            paddingTop: '1vh',
            paddingBottom: '12vh',
        },
        featuresHeader: {
            paddingBottom: `${isWideDisplay ? '8vh !important' : '0'}`,
        },
        featuresTitle: {
            width: '100%',
        },
        featuresContent: {
            fontWeight: 'normal',
        },
        icon: {
            height: 60,
        },
        contactCard: {
            textAlign: 'center',
            opacity: `${isWideDisplay ? '85%' : '100%'}`,
        },
        button: {
            margin: theme.spacing(1),
        },
        action: {
            display: 'flex !important',
            justifyContent: 'space-around',
            paddingBottom: '24px !important',
        },
        formControl: {
            paddingBottom: '24px !important',
        },
        visible: {
            display: 'block',
        },
        hidden: {
            display: 'none',
        }
    }))()
}

const loginStyles = makeStyles(() => ({
    loginContainer: { 
        height: '90vh'
    },
    loginPaper: {
        textAlign: 'center',
        paddingTop:  '5vh',
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'transparent',
    },
    loginCard: {
        width: 650,
        maxWidth: 650,
    },
    button: {
        margin: theme.spacing(1),
    },
    action: {
        display: 'flex !important',
        justifyContent: 'space-around',
        paddingBottom: '24px !important',
    },
    visible: {
        display: 'block',
    },
    hidden: {
        display: 'none',
    },
}))

const contactStyles = makeStyles(() => ({
    contactContainer: { 
        height: '90vh',
    },
    contactPaper: {
        textAlign: 'center',
        paddingTop:  '5vh',
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'transparent',
    },
    contactCard: {
        width: 650,
        maxWidth: 650,
    },
    button: {
        margin: theme.spacing(1),
    },
    action: {
        display: 'flex !important',
        justifyContent: 'space-around',
        paddingBottom: '24px !important',
    },
    formControl: {
        paddingBottom: '24px !important',
    },
    visible: {
        display: 'block',
    },
    hidden: {
        display: 'none',
    },
}))

const careersStyles = willScroll => {
    return makeStyles(() => ({
        careersContainer: { 
            height: willScroll ? '100%' : '90vh',
        },
        careersPaper: {
            paddingTop:  '5vh',
            display: 'flex',
            justifyContent: 'space-around',
            backgroundColor: 'transparent',
        },
        careersCard: {
            width: 650,
            maxWidth: 650,
        },
    }))()
}

const commonContainerStyles = makeStyles(() => ({
    background: {
        background: 'linear-gradient(to top, rgba(52, 37, 128, 0.08), rgba(52, 37, 128, 0) 70%)',
    },
    mainContainer: {
      marginTop: '90px',
      minHeight: '90vh',
    },
    upButton: {
      position: 'fixed',
      bottom: '80px',
      right: '10px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.light,
    },
}))

const longContentStyles = makeStyles(() => ({
    longContainer: { 
        height: '100%',
    },
    longPaper: {
        paddingTop:  '5vh',
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'transparent',
    },
    longCard: {
        width: 800,
        maxWidth: 800,
    },
}))

const fundListStyles = makeStyles(() => ({
    fundContainer: {
        height: '100%',
    },
    fundPaper: {
        paddingTop:  '0',
        justifyContent: 'space-around',
        backgroundColor: 'transparent',
    },
    longPaper: {
        paddingTop:  '5vh',
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'transparent',
    },
    longCard: {
        width: 800,
        maxWidth: 800,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
            margin: 'auto',
        },
    },
}))

const fundStyles = makeStyles(() => ({
    fundContainer: {
        height: '100%',
    },
    fundItem: {
        margin: 'auto !important',
    },
    fundPaper: {
        paddingTop:  '0',
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'transparent',
    },
    fundCard: {
        width: '100%',
        maxWidth: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: {
            width: 800,
            maxWidth: 800,
        },
    },
    kvpTable: {
        backgroundColor: '#e0e0e0',
    },
    kvpLeft: {
        borderBottom: '1px solid #fff'
    },
    kvpRight: {
        borderBottom: '1px solid #fff',
        borderLeft: '1px solid #fff'
    },
    positive: {
        padding: '0 !important',
        color: theme.palette.success.dark + ' !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.65rem !important'
        },
    },
    negative: {
        padding: '0 !important',
        color: theme.palette.error.main + ' !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.65rem !important'
        },
    },
    tableHeader: {
        padding: '0 !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.65rem !important'
        },
    },
    chart: {
        [theme.breakpoints.down('sm')]: {
            width: 400
        },
        [theme.breakpoints.up('md')]: {
            width: 800
        }
    },
    button: {
        margin: 'auto',
    },
    center: {
        textAlign: 'center',
    },
    tile: {
        backgroundColor: theme.palette.background.paper,
        padding: '2px',
    },
    tileContents: {
        backgroundColor: grey[300],
        textAlign: 'center',
        height: '100%',
        padding: theme.spacing(1),
    },
    tileTitle: {
        marginTop: '20px',
        marginBottom: 0,
    },
    tileValue: {
        marginTop: '40px',
    },
    allocationButton: {
        marginTop: '20px',
    },
    loading: {
        display: 'none',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.light,
    },
    snackbar: {
        bottom: '60px !important',
    },
    yes: {
        color: theme.palette.primary.light,
    },
    no: {
        color: theme.palette.error.light,
    },
    later: {
        color: theme.palette.common.white,
    },
}))

const newsletterStyles = makeStyles(() => ({
    newsletterContainer: { 
        height: '100%',
    },
    newsletterPaper: {
        marginTop: '-15px',
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'transparent',
    },
    newsletterCard: {
        width: 800,
        maxWidth: 800,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.light,
    },
}))

const privateHomeStyles = makeStyles(() => ({
    action: {
        height: '100%',
    },
    showAction: {
        height: '100%',
    },
    hideAction: {
        height: '0 !important',
    },
    root: {
        height: '100%',
        position: 'relative',
        marginLeft: 'auto',
        overflow: 'initial',
        background: '#ffffff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
    },
    showNoFlexRoot: {
        height: '100%',
        position: 'relative',
        marginLeft: 'auto',
        overflow: 'initial',
        background: '#ffffff',
        display: 'block',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
    },
    hideNoFlexRoot: {
        height: '0 !important',
        position: 'relative',
        marginLeft: 'auto',
        overflow: 'initial',
        background: '#ffffff',
        display: 'none',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
    },
    showRoot: {
        height: '100%',
        position: 'relative',
        marginLeft: 'auto',
        overflow: 'initial',
        background: '#ffffff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
    },
    hideRoot: {
        height: '0 !important',
        position: 'relative',
        marginLeft: 'auto',
        overflow: 'initial',
        background: '#ffffff',
        display: 'none',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
    },
    active: {
        background: '#f6f6f6 !important',
        height: '100%',
        position: 'relative',
        marginLeft: 'auto',
        overflow: 'initial',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
    },
    iconContainer: {
        marginLeft: 0,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        width: 60,
        height: 0,
        minHeight: 60,
        backgroundColor: 'transparent',
        position: 'relative',
        backgroundSize: 'contain !important',
        [theme.breakpoints.up('md')]: {
          width: '100%',
          minWidth: 60,
          maxWidth: 60,
          marginTop: 0,
          marginLeft: theme.spacing(2),
        },
    },
    icon: {
        fontSize: "3rem !important",
        color: theme.palette.primary.main,
    },
    title: {
        marginTop: theme.spacing(3),
    },
    homeContainer: {
        height: '100%',
    },
    homePaper: {
        paddingTop:  '0',
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'transparent',
    },
}))

export {
    homeStyles,
    loginStyles,
    contactStyles,
    careersStyles,
    commonContainerStyles,
    longContentStyles,
    fundListStyles,
    fundStyles,
    newsletterStyles,
    privateHomeStyles,
    landingPageStyles
}