import React, { useEffect, useState, Fragment } from 'react'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import showdown from 'showdown'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { fundStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export default function Fund(props) {
    const { slug } = useParams()
    const connectionRequests = 'connection-requests'
    const [showSnack, setShowSnack] = useState(false)
    const [respondedToSnack, setRespondedToSnack] = useState(false)
    const [snackConfirmation, setSnackConfirmation] = useState(null)
    const [data, setData] = useState({})
    const [keyValuePairs, setKeyValuePairs] = useState([])
    const [returns, setReturns] = useState([])
    const [newsletters, setNewsletters] = useState(null)
    const [documents, setDocuments] = useState(null)
    const [dueDiligenceDocuments, setDueDiligenceDocuments] = useState(null)
    const [status, setStatus] = useState('loading')
    const [connectionsRequested] = useState(localStorage.getItem(connectionRequests) != null ? localStorage.getItem(connectionRequests).split('|') : '')
    const [tiles, setTiles] = useState([])
    const [isSmallScreen] = useState(window.visualViewport && window.visualViewport.width < 700)
    const [allocation, setAllocationStatus] = useState('Loading')
    const [fundingDocuments, setFundingDocumentsStatus] = useState('None')
    const growth = returnsForPeriod => returnsForPeriod.map(change => 1 + (change / 100)).reduce((a, c) => a * c)
    const stdev = monthlyReturns => {
        const mean = monthlyReturns.reduce((a, c) => a + c) / monthlyReturns.length
        return (Math.sqrt(monthlyReturns.reduce((a, c) => a + ((c - mean) ** 2), 0) / (monthlyReturns.length - 1))) * Math.sqrt(12)
    }
    const maxDrawdown = monthlyReturns => {
        const _maxDrawdown = equityCurve => {
            let highWaterMark = -Infinity
            let maxDd = -Infinity
            for (let i = 0; i < equityCurve.length; ++i) {     
                if (equityCurve[i] > highWaterMark) {
                    highWaterMark = equityCurve[i]
                }
                let dd = (highWaterMark - equityCurve[i]) / highWaterMark
                if (dd > maxDd) {
                    maxDd = dd
                }
            }
            return maxDd == -Infinity ? 0 : maxDd
        }
        let nav = 100
        return _maxDrawdown(monthlyReturns.map(r => nav += nav * (r / 100))) * 100
    }
    const calculateMetrics = data => {
        const riskFreeRate = 2
        const sortedReturnValues = data.monthly_returns.sort((a, b) => (new Date(a.date)) - (new Date(b.date))).map(r => r.return)
        const annualReturn = ((growth(sortedReturnValues) ** (12 / sortedReturnValues.length) - 1) * 100)
        const riskFreeAnnualReturn = annualReturn - riskFreeRate
        const mean = sortedReturnValues.reduce((a, c) => a + c) / sortedReturnValues.length
        const negativeReturnValues = sortedReturnValues.filter(r => r < 0)
        setTiles([
            {
                title: 'ANN. RETURN',
                value: annualReturn.toFixed(1) + '%'
            },
            {
                title: 'AVG.&nbsp;MONTHLY&nbsp;RETURN',
                value: mean.toFixed(1) + '%'
            },
            {
                title: 'MAX. DRAWDOWN',
                value: negativeReturnValues.length ? maxDrawdown(sortedReturnValues).toFixed(1) + '%' : 'N/A'
            },
            {
                title: 'SHARPE RATIO',
                value: (riskFreeAnnualReturn / stdev(sortedReturnValues)).toFixed(1)
            },
            {
                title: 'SORTINO RATIO',
                value: negativeReturnValues.length && negativeReturnValues.length > 1 ? (riskFreeAnnualReturn / stdev(negativeReturnValues)).toFixed(1) : 'N/A'
            },
            {
                title: 'INCEPTION DATE',
                value: new Date(data.inception)
                            .toLocaleDateString('en-GB', {
                                month : 'short',
                                year : 'numeric'
                            })
            }
        ])
    }
    const formatReturns = returns => {
        const returnsData = returns.map(r => ({ year: r.date.split('-')[0], month: r.date.split('-')[1], change: r.return })).sort((a, b) => b.year - a.year)
        const formattedReturns = returnsData.reduce((acc, cur) => {
            if (acc[cur.year] == null) {
                acc[cur.year] = { year: cur.year }
            }
            acc[cur.year][cur.month] = cur.change.toFixed(1)
            return acc
        }, {})
        const sortedReturns = Object.keys(formattedReturns).map(k => formattedReturns[k]).sort((a, b) => b.year - a.year).sort((a, b) => b.month - a.month)
        return sortedReturns
    }
    const requestAccess = () => {
        setStatus('sending')
        const subject = 'Connection request'
        const name = localStorage.getItem('user-name')
        const email = localStorage.getItem('user-email')
        const message = `A request has been made by ${name} (${email}) to access the newsletters for ${data.name}. Please add a connection for this fund/user pair to enable this.`
        axios
            .post(`${api_root}/messages`, { subject, message, name, email })
            .then(result => {
                setStatus('sent')
                localStorage.setItem(connectionRequests,  `${localStorage.getItem(connectionRequests)}|${data.slug}`)
                const actionBody = {
                    action_type: 'click',
                    description: 'connection request (access button)',
                    fund: slug,
                    user: localStorage.getItem('user-email')
                }
                return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            })
            .then(result => {})
            .catch(err => {
                setStatus('error')
            })
    }
    const actionPerformed = (action, resource) => {
        const actionBody = {
            action_type: 'click',
            description: `${action} ${resource}`,
            fund: slug,
            user: localStorage.getItem('user-email')
        }
        axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(result => {})
            .catch(err => {})
    }
    const onPlayPodcast = () => {
        const actionBody = {
            action_type: 'click',
            description: `play on podcast episode ${data.name}`,
            fund: data.slug,
            user: localStorage.getItem('user-email')
        }
        return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(res => {})
            .catch(err => {})
    }
    const requestAllocation = () => {
        setAllocationStatus('Loading')
        const subject = 'Allocation Request'
        const name = localStorage.getItem('user-name')
        const email = localStorage.getItem('user-email')
        const message = `A request has been made by ${name} (${email}) to make an allocation to ${data.name}`
        axios
            .post(`${api_root}/messages`, { subject, message, name, email })
            .then(result => {
                setStatus('sent')
                const actionBody = {
                    action_type: 'click',
                    description: 'allocation request',
                    fund: slug,
                    user: localStorage.getItem('user-email')
                }
                return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            })
            .then(result => {
                axios.post(`${api_root}/allocations`, { fund: slug, amount: 100 }, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                    .then(res => {
                        if (fundingDocuments === 'None') {
                            setAllocationStatus('Redirecting')
                            window.location.href = window.location.protocol + "//" + window.location.host + '/home?docs=1'
                        } else {
                            setAllocationStatus('Pending')
                        }
                    })
            })
            .catch(err => {})
    }
    useEffect(() => {
        setStatus('loading')
        if (navigator.onLine) {
            axios
                .get(`${api_root}/funds/${slug}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                    setData(result.data)
                    if (result.data.message !== 'wrong user type') {
                        setReturns(formatReturns(result.data.monthly_returns))
                        setKeyValuePairs(result.data.key_value_pairs)
                        setNewsletters(result.data.newsletters != null ? result.data.newsletters.sort((a, b) => (new Date(b.date)) - (new Date(a.date))) : null)
                        setDocuments(result.data.documents != null ? result.data.documents : null)
                        setDueDiligenceDocuments(result.data.due_diligence_documents != null ? result.data.due_diligence_documents : null)
                        if (result.data.monthly_returns.length) calculateMetrics(result.data)
                        setRespondedToSnack(localStorage.getItem(result.data.slug + '-snack') ? true : false)
                        localStorage.setItem(slug, JSON.stringify(result.data))
                    }
                    setStatus('ready')
                    const actionBody = {
                        action_type: 'visit',
                        description: 'fund page',
                        fund: slug,
                        user: localStorage.getItem('user-email')
                    }
                    return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                })
                .then(result => {
                    axios.get(`${api_root}/allocations/${slug}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                    .then(result => {
                        result.data.status == null ? setAllocationStatus('None') : setAllocationStatus(result.data.status)
                        axios.get(`${api_root}/allocations/docs`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                            .then(res => {
                                setFundingDocumentsStatus(res.data.status)
                            })
                            .catch(err => {
                                console.log('Error fetching documents status', err)
                            })
                    })
                    .catch(err => {
                        console.log('Error fetching allocation status', err)
                    })
                })
                .catch(err => {
                    const loc = window.location
                    if (!err.response) {
                        const data = JSON.parse(localStorage.getItem(slug))
                        if (data != null) {
                            setData(data)
                            setReturns(formatReturns(data.monthly_returns))
                            setNewsletters(data.newsletters != null ? data.newsletters.sort((a, b) => (new Date(b.date)) - (new Date(a.date))) : null)
                            calculateMetrics(data)
                            setStatus('ready')
                        } else {
                            setStatus('no data')
                        }
                        return 'offline'
                    }
                    if (err.response.status === 401) window.location.href = loc.protocol + "//" + loc.host + '/login'
                    if (err.response.status === 404) window.location.href = loc.protocol + "//" + loc.host + '/funds'
                    setStatus('ready')
                })
        } else {
            const data = JSON.parse(localStorage.getItem(slug))
            if (data != null) {
                setData(data)
                setReturns(formatReturns(data.monthly_returns))
                setNewsletters(data.newsletters != null ? data.newsletters.sort((a, b) => (new Date(b.date)) - (new Date(a.date))) : null)
                calculateMetrics(data)
                setStatus('ready')
            } else {
                setStatus('no data')
            }
            setRespondedToSnack(localStorage.getItem(slug + '-snack') ? true : false)
        }
    }, [slug])
    const converter = new showdown.Converter()
    const classes = fundStyles()
    const handleClose = message => () => {
        const subject = `Response (${message.toUpperCase()}) to learn more message`
        const name = localStorage.getItem('user-name')
        const email = localStorage.getItem('user-email')
        let messageBody = `Fund: ${data.name}\n${name} responded ${message} when asked if they would like to learn more about the fund.`
        if (message === 'yes' || message === 'no') localStorage.setItem(data.slug + '-snack', true)
        messageBody += message === 'yes' ? `\n${name} should be connected to ${data.name} and contacted with more information.` : '\nNo action is required at this time.'
        setRespondedToSnack(true)
        axios
            .post(`${api_root}/messages`, { subject, message: messageBody, name, email })
            .then(result => {
                if (message === 'yes') setSnackConfirmation('You will be contacted shortly with more information about this fund.')
                if (message === 'no') setSnackConfirmation('We won\'t ask you about this fund again.')
                if (message === 'later') setSnackConfirmation('We\'ll ask you about this fund another time.')                
                const actionBody = {
                    action_type: 'click',
                    description: `responded "${message}" to prompt for more information`,
                    fund: slug,
                    user: localStorage.getItem('user-email')
                }
                return axios.post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            })
            .then(result => {})
            .catch(err => {
                setSnackConfirmation('error')
            })
    }
    useEffect(_ => {
        const handleScroll = _ => {
          const el = document.getElementById('hashTagList')
          const inCorrectPosition = el && el.getBoundingClientRect().bottom <= window.innerHeight
          setShowSnack(inCorrectPosition)
        }
        window.addEventListener('scroll', handleScroll)
        return _ => {
          window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    const replaceVariables = content => {
        const sortedRowValues = Object.keys(returns).length > 0 ? Object.keys(returns[0]).reduce((a, c) => !isNaN(Number(c)) ? a.concat(Number(c)) : a, []).sort((a, b) => a - b).map(k => returns[0][k < 10 ? '0' + k : k]) : null
        const variables = tiles.length > 0 && sortedRowValues != null ? {
            SHARPE_RATIO: tiles.find(t => t.title === 'SHARPE RATIO').value,
            SORTINO_RATIO: tiles.find(t => t.title === 'SORTINO RATIO').value,
            ANN_RETURN: tiles.find(t => t.title === 'ANN. RETURN').value,
            MAX_DRAWDOWN: tiles.find(t => t.title === 'MAX. DRAWDOWN').value,
            CUM_RETURN: ((growth(data.monthly_returns.sort((a, b) => (new Date(a.date)) - (new Date(b.date))).map(r => r.return)) - 1) * 100).toFixed(1) + '%',
            CURRENT_YTD: ((growth(sortedRowValues) - 1) * 100).toFixed(1) + '%'
        } : null
        return variables != null ? Object.keys(variables).reduce((acc, cur) => {
            const regex = new RegExp(cur, 'gi')
            return acc.replace(regex, variables[cur])
        }, content) : content
    }
    return (
      <div className={classes.fundContainer}>
          <CommonContainer>
              <Paper elevation={0} className={classes.fundPaper}>
                <Grid container>
                { data.message !== 'wrong user type' ?
                    <Grid item className={classes.fundItem}>
                        <Typography variant="h4" align="center" gutterBottom>
                            {status === 'no data' ? 'Only funds that have been visited before are available whilst offline' : data.name}
                        </Typography>
                        { status !== 'no data' ?
                        <Card className={status === 'loading' ? classes.loading : classes.fundCard}>
                            <CardContent>
                                <div className={classes.tileRoot}>
                                    <ImageList rowHeight={120} className={classes.gridList} cols={isSmallScreen ? 2 : 3}>
                                        {tiles.map((tile) => (
                                        <ImageListItem key={tile.title} className={classes.tile}>
                                            <div className={classes.tileContents}>
                                                <p className={classes.tileTitle} dangerouslySetInnerHTML={{__html: tile.title}} />
                                                <h2 className={classes.tileValue}>{tile.value}</h2>
                                            </div>  
                                        </ImageListItem>
                                        ))}
                                    </ImageList>
                                </div>
                                <div className={classes.allocationButton}>
                                    <div className={classes.center}>
                                        { allocation === 'Granted' ?
                                        <Alert severity="info">You have been granted an allocation of $100,000 which is waiting to be funded</Alert>
                                        :
                                        <Button variant="contained" color={allocation !== 'None' ? 'secondary' : 'primary'} className={classes.button} onClick={requestAllocation} disabled={allocation === 'Pending' || allocation === 'Loading'}>
                                            {allocation !== 'Pending' ? (allocation !== 'Redirecting' ? 'Request Allocation' : 'Redirecting') : 'Allocation Request Pending Review'}
                                        </Button>
                                        }
                                    </div>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: converter.makeHtml(replaceVariables(data.full_description))}} />
                                <p><em>Sharpe and Sortino ratios assume 2% risk free rate.</em></p>
                                { status == 'no data' ? null : keyValuePairs.length > 0 ?
                                    <TableContainer>
                                        <Table className={classes.kvpTable} size="medium" aria-label="Key value pairs">
                                            <TableBody>
                                                {keyValuePairs.map(kvp => (
                                                    <TableRow key={kvp.key}>
                                                        <TableCell className={classes.kvpLeft} align="left">{kvp.key}</TableCell>
                                                        <TableCell className={classes.kvpRight} align="center">{kvp.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                : null }
                                <h2>
                                    Performance Summary (%)
                                </h2>
                                {returns == null ? null :
                                    <TableContainer>
                                    <Table className={classes.table} size="small" aria-label="Monthly returns">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className={classes.tableHeader}>Year</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Jan</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Feb</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Mar</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Apr</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>May</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Jun</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Jul</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Aug</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Sep</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Oct</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Nov</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>Dec</TableCell>
                                          <TableCell align="right" className={classes.tableHeader}>YTD</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {returns.map((row) => {
                                          const sortedRowValues = Object.keys(row).reduce((a, c) => !isNaN(Number(c)) ? a.concat(Number(c)) : a, []).sort((a, b) => a - b).map(k => row[k < 10 ? '0' + k : k])
                                          const ytd = ((growth(sortedRowValues) - 1) * 100).toFixed(1)
                                          return (
                                          <TableRow key={row.year}>
                                            <TableCell component="th" scope="row" className={classes.tableHeader}>
                                              {row.year}
                                            </TableCell>
                                            <TableCell align="right" className={ row["01"] > 0 ? classes.positive : classes.negative }>{row["01"]}</TableCell>
                                            <TableCell align="right" className={ row["02"] > 0 ? classes.positive : classes.negative }>{row["02"]}</TableCell>
                                            <TableCell align="right" className={ row["03"] > 0 ? classes.positive : classes.negative }>{row["03"]}</TableCell>
                                            <TableCell align="right" className={ row["04"] > 0 ? classes.positive : classes.negative }>{row["04"]}</TableCell>
                                            <TableCell align="right" className={ row["05"] > 0 ? classes.positive : classes.negative }>{row["05"]}</TableCell>
                                            <TableCell align="right" className={ row["06"] > 0 ? classes.positive : classes.negative }>{row["06"]}</TableCell>
                                            <TableCell align="right" className={ row["07"] > 0 ? classes.positive : classes.negative }>{row["07"]}</TableCell>
                                            <TableCell align="right" className={ row["08"] > 0 ? classes.positive : classes.negative }>{row["08"]}</TableCell>
                                            <TableCell align="right" className={ row["09"] > 0 ? classes.positive : classes.negative }>{row["09"]}</TableCell>
                                            <TableCell align="right" className={ row["10"] > 0 ? classes.positive : classes.negative }>{row["10"]}</TableCell>
                                            <TableCell align="right" className={ row["11"] > 0 ? classes.positive : classes.negative }>{row["11"]}</TableCell>
                                            <TableCell align="right" className={ row["12"] > 0 ? classes.positive : classes.negative }>{row["12"]}</TableCell>
                                            <TableCell align="right" className={ ytd > 0 ? classes.positive : classes.negative }>{ytd}</TableCell>
                                          </TableRow>
                                        )})}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                }
                                { data.podcasts != null && data.podcasts.length > 0 ?
                                    <Fragment>
                                    <h2>Podcast</h2>
                                    { data.podcasts.map(podcast => <div key={podcast.guid}>
                                        <p><strong>{podcast.title}</strong></p>
                                        <p>{podcast.description}</p>
                                        <AudioPlayer onPlay={onPlayPodcast} src={podcast.mp3.provider === 'local' ? `${api_root}${podcast.mp3.url}` : podcast.mp3.url} customVolumeControls={[]} customAdditionalControls={[]} />
                                    </div>) }
                                    </Fragment> : null
                                }
                                { data.connected === false ?
                                    <Fragment>
                                    <h2>Newsletters and Documents</h2>
                                    <div>
                                        <p>
                                            {
                                                status === 'sent' || connectionsRequested.includes(data.slug) ?
                                                'Your request for access is being processed' :
                                                'This fund has more information available. Click the button below to request access to this funds newsletters and documents.'
                                            }
                                        </p>
                                        <div className={classes.center}>
                                            <Button variant="contained" color={status === 'sent' ? 'secondary' : 'primary'} className={classes.button} onClick={requestAccess} disabled={status === 'sending' || status === 'sent' || connectionsRequested.includes(data.slug)}>
                                                {status === 'sent' || connectionsRequested.includes(data.slug) ? 'Access To Fund Materials Requested' : 'Request Access To Fund Materials'}
                                            </Button>
                                        </div>
                                    </div>
                                    </Fragment> : null
                                }
                                {navigator.onLine ?
                                    <Fragment>
                                    {newsletters != null && newsletters.length ?
                                        <Fragment>
                                            <h2>Newsletters</h2>
                                            <ul>
                                            {newsletters.map(newsletter => (
                                                <li key={newsletter.guid}><Link href={newsletter.type === 'html' ? `/newsletters/${newsletter.guid}` : `/pdf/${newsletter.guid}`} onClick={() => actionPerformed('newsletter read', newsletter.guid)}color="primary">{newsletter.title}</Link></li>
                                            ))}
                                            </ul>
                                        </Fragment> : null
                                    }
                                    {documents != null && documents.length ?
                                        <Fragment>
                                            <h2>Documents</h2>
                                            <ul>
                                            {documents.map((doc, idx) => (
                                                <li key={idx}><Link href={doc.file.url} target="_blank" download={doc.file.url} onClick={() => actionPerformed('document download', doc.title)} color="primary">{doc.title}</Link></li>
                                            ))}
                                            </ul>
                                        </Fragment> : null
                                    }
                                    {dueDiligenceDocuments != null && dueDiligenceDocuments.length ?
                                        <Fragment>
                                            <h2>Due Diligence Documents</h2>
                                            <ul>
                                            {dueDiligenceDocuments.map((doc, idx) => (
                                                <li key={idx}><Link href={doc.file.url} target="_blank" download={doc.file.url} onClick={() => actionPerformed('due diligence document download', doc.title)} color="primary">{doc.title}</Link></li>
                                            ))}
                                            </ul>
                                        </Fragment> : null
                                    }
                                    </Fragment> :
                                    <div>
                                        <p>
                                            Newsletters and Documents are not available whilst offline.
                                        </p>
                                    </div>
                                }
                                <h2>
                                    HashTags
                                </h2>
                                {data.fund_tags != null && data.fund_tags.length ?
                                    <ul id='hashTagList'>
                                    {data.fund_tags.filter(tag => tag.name.indexOf('#') === 0).map(tag => (
                                        <li key={tag.name}><Link href={`/tags/${tag.name.split('#')[1]}`} color="primary">{tag.name}</Link></li>
                                    ))}
                                    </ul> : null
                                }
                                <Snackbar
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                    }}
                                    className={classes.snackbar}
                                    open={showSnack && !respondedToSnack && navigator.onLine}
                                    message="Would you like to learn more about this fund?"
                                    action={
                                    <React.Fragment>
                                        <Button className={classes.yes} size="small" onClick={handleClose('yes')}>
                                        Yes
                                        </Button>
                                        <Button className={classes.no} size="small" onClick={handleClose('no')}>
                                        No
                                        </Button>
                                        <Button className={classes.later} size="small" onClick={handleClose('later')}>
                                        Not Now
                                        </Button>
                                    </React.Fragment>
                                    }
                                />
                                <Snackbar
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                    }}
                                    className={classes.snackbar}
                                    open={snackConfirmation !== null}
                                    onClose={() => setSnackConfirmation(null)}
                                    autoHideDuration={5000}
                                >
                                    <Alert severity="success">
                                        Thank you for your response! {snackConfirmation}
                                    </Alert>
                                </Snackbar>
                            </CardContent>
                        </Card> : null }
                    </Grid> :
                    <Grid item className={classes.fundItem}>
                        <Card className={status === 'loading' ? classes.loading : classes.fundCard}>
                            <CardContent>
                                <h2>Your account does not have permission to view this fund.</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                </Grid>
              </Paper>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <Backdrop className={classes.backdrop} open={status === 'loading' ? true : false}>
                <CircularProgress color="inherit" disableShrink />
              </Backdrop>
          </CommonContainer>
      </div>
    )
}