import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import LinearProgress from '@mui/material/LinearProgress'
import ListIcon from '@mui/icons-material/List'
import HowToReg from '@mui/icons-material/HowToReg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import { DropzoneArea } from 'material-ui-dropzone'
import { privateHomeStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


export default function PrivateHome(props) {
    const [docsProgress, setDocsProgress] = useState(window.location.search.indexOf('docs=1') > 0 ? 1 : 0)
    const [fundingDocuments, setFundingDocumentsStatus] = useState('Loading')
    const [userId, setUserId] = useState(null)
    const ref = useRef(null)
    const getDocsInstructions = () => {
        const content = {
            None: 'Upload verification documents to enable allocation requests',
            Submitted: 'Your documents have been submitted and are being verified',
            Action: 'Further action is required. You will be contacted by one of our representatives',
            Verified: 'Your identity documents have been verified. Your account is ready to make allocations',
            Loading: 'Loading status. Please wait'
        }
        return fundingDocuments != null ? content[fundingDocuments] : content['None']
    }
    const scrollForForm = () => {
        document.getElementById('docs').scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    const showForm = () => {
        if (fundingDocuments == null || fundingDocuments === 'None') {
            setDocsProgress(1)
            setTimeout(scrollForForm, 500)
        } else {
            setDocsProgress(5)
            setTimeout(scrollForForm, 500)
        }
    }
    const uploadFile = (file, field, progress) => {
        if (file.length > 0 && field != null && progress != null) {
            const params = new FormData()
            params.append('ref', 'user')
            params.append('source', 'users-permissions')
            params.append('files', file[0])
            params.append('field', field)
            params.append('refId', userId)
            axios.post(`${api_root}/upload`, params, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token'), 'Content-Type': 'multipart/form-data' } })
                .then(result => {
                    setDocsProgress(progress + 1)
                    if (progress === 4) {
                        axios.put(`${api_root}/allocations/docs`, {}, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                            .then(result => {})
                            .catch(err => {})
                    }
                })
                .catch(err => console.log('Error uploading document', err))
        }
    }
    useEffect(() => {
        ref.current.showSpinner(true)
        if (navigator.onLine) {
            const actionBody = {
                action_type: 'visit',
                description: 'home',
                fund: null,
                user: localStorage.getItem('user-email')
            }
            axios
                .post(`${api_root}/action-streams`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(result => {
                    ref.current.showSpinner(false)
                    axios.get(`${api_root}/allocations/docs`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                        .then(res => {
                            setFundingDocumentsStatus(res.data.status)
                            setUserId(res.data.userId)
                        })
                        .catch(err => {
                            console.log('Error fetching documents status', err)
                        })
                })
                .catch(err => {
                    ref.current.showSpinner(false)
                })
        } else {
            ref.current.showSpinner(false)
        }
        if (docsProgress > 0) document.getElementById('docs').scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, [])
    const classes = privateHomeStyles()
    return(
        <div className={classes.homeContainer}>
            <CommonContainer ref={ref} section='home'>
                <Paper elevation={0} className={classes.homePaper}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h5" component="h2" align="left" gutterBottom className={classes.title}>{`Welcome ${localStorage.getItem('user-name')}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardActionArea className={classes.action} href="/funds">
                                <Card className={classes.root}>
                                    <div className={classes.iconContainer}>
                                        <ListIcon className={classes.icon} />
                                    </div>
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            Hedge Funds
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            View our curated list of Hedge Funds.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardActionArea className={classes.action} href="/podcast">
                                <Card className={classes.root}>
                                    <div className={classes.iconContainer}>
                                        <PlaylistPlayIcon className={classes.icon} />
                                    </div>
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            Podcast
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            Terry Hau, CEO of HedgeVista interviews Hedge Fund professionals.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardActionArea className={classes.action} disabled={docsProgress > 0} onClick={showForm} id='docs'>
                                <Card className={docsProgress > 0 ? classes.active : classes.root}>
                                    <div className={classes.iconContainer}>
                                        <HowToReg className={classes.icon} />
                                    </div>
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            Identity Documents
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            { getDocsInstructions() }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                        <Grid item xs={12}>
                                <Card className={docsProgress === 1 ? classes.showNoFlexRoot : classes.hideNoFlexRoot}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            Government ID (1 of 4)
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            Upload a copy of your government ID or the identity page of your passport here
                                        </Typography>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            filesLimit={1}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                            dropzoneText={"Upload your government ID document here"}
                                            onChange={(files) => uploadFile(files, 'government_id', 1)}
                                        />
                                        <LinearProgress variant="determinate" value={0} />
                                    </CardContent>
                                </Card>
                                <Card className={docsProgress === 2 ? classes.showNoFlexRoot : classes.hideNoFlexRoot}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            Selfie (2 of 4)
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            Upload a selfie of you holding a copy of your government ID or the identity page of your passport.
                                            Your face and the document must be clearly visible
                                        </Typography>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            filesLimit={1}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                            dropzoneText={"Upload your selfie here"}
                                            onChange={(files) => uploadFile(files, 'selfie', 2)}
                                        />
                                        <LinearProgress variant="determinate" value={25} />
                                    </CardContent>
                                </Card>
                                <Card className={docsProgress === 3 ? classes.showNoFlexRoot : classes.hideNoFlexRoot}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            Proof of Address (3 of 4)
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            Upload a recent bill or government communication showing your address
                                        </Typography>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            filesLimit={1}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                            dropzoneText={"Upload proof of address here"}
                                            onChange={(files) => uploadFile(files, 'proof_of_address', 3)}
                                        />
                                        <LinearProgress variant="determinate" value={50} />
                                    </CardContent>
                                </Card>
                                <Card className={docsProgress === 4 ? classes.showNoFlexRoot : classes.hideNoFlexRoot}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            Proof of Accredited Status ( 4 of 4)
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            Upload a document that proves your accredited investor status (bank statement, pay stub etc.)
                                        </Typography>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            filesLimit={1}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                            dropzoneText={"Upload proof of accredited status"}
                                            onChange={(files) => uploadFile(files, 'proof_of_accredited_status', 4)}
                                        />
                                        <LinearProgress variant="determinate" value={75} />
                                    </CardContent>
                                </Card>
                                <Card className={docsProgress === 5 ? classes.showNoFlexRoot : classes.hideNoFlexRoot}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2" align="left" gutterBottom>
                                            Documents Submitted
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom>
                                            You have uploaded all the required documents
                                        </Typography>
                                        <LinearProgress variant="determinate" value={100} />
                                    </CardContent>
                                </Card>
                        </Grid>

                    </Grid>
                </Paper>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </CommonContainer>
        </div> 
    ) 
}