import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import axios from 'axios'
import { api_root } from '../config'
import { modalStyles } from '../style/ComponentStyles'


export default function AgreementModal(props) {
    const [visible, setVisible] = useState(true)
    const sendAgreement = () => {
        const actionBody = {
            user: localStorage.getItem('user-email') 
        }
        axios
            .post(`${api_root}/action-streams/accept`, actionBody, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(result => {
                localStorage.setItem('accepted_terms', new Date())
                setVisible(false)
            })
            .catch(err => {})
    }
    const classes = modalStyles()
    return(
        <Modal
        open={visible}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modal}>
          <h2 id="simple-modal-title">Terms and Conditions</h2>
          <p id="simple-modal-description">
          In continuing to use HedgeVista you agree with the following terms and conditions
          </p>
          <ul>
            <li><Link href="/user-agreement" color="primary">User Agreement</Link></li>
            <li><Link href="/terms" color="primary">Terms and Conditions</Link></li>
            <li><Link href="/privacy" color="primary">Privacy Policy</Link></li>
          </ul>
          <Button variant="contained" color="primary" className={classes.button} onClick={sendAgreement}>I Agree</Button>
        </div>
      </Modal>
    )
}