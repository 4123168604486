import React, { useState, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import axios from 'axios'
import { contactStyles } from '../../style/ContainerStyles'
import CommonContainer from '../common/CommonContainer'
import { api_root } from '../../config'


export default function Contact(props) {
    const ref = useRef(null)
    const [status, setStatus] = useState('ready')
    const [subject] = useState(props.subject || 'Message from contact form')
    const [message, setMessage] = useState(props.subject === 'Account request' ? 'This person has requested an account' : '')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [showErrors, setShowErrors] = useState(false)
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const sendMessage = () => {
        setShowErrors(false)
        if (message === '' || name === '' || email === '' || !emailRegExp.test(email)) {
            if (!emailRegExp.test(email)) setEmail('')
            setStatus('ready')
            return setShowErrors(true)
        }
        ref.current.showSpinner(true)
        setStatus('sending')
        axios
            .post(`${api_root}/messages`, { subject, message, name, email })
            .then(result => {
                setStatus('sent')
                setName('')
                setMessage('')
                setEmail('')
                ref.current.showSpinner(false)
            })
            .catch(err => {
                setStatus('error')
                ref.current.showSpinner(false)
            })
    }
    const classes = contactStyles()
    return (
        <div className={classes.contactContainer}>
            <CommonContainer ref={ref} section='contact'>
                <Paper elevation={0} className={classes.contactPaper}>
                    <Card className={classes.contactCard}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                {subject === 'Account request' ? 'Request Access' : 'Contact Us'}
                            </Typography>
                            { status === 'error' ? <Typography color="error">Something went wrong. Please try again.</Typography> : null }
                            { status === 'sent' ? <Typography color="primary">Thank you for contacting us.</Typography> : null }
                            <div className={status === 'sent' ? classes.hidden : classes.visible }>
                                <Typography gutterBottom>
                                    {subject === 'Account request' ? 'Please fill out the form below to request access.' : 'To request access or ask any questions please contact us.'}
                                </Typography>
                                <form noValidate autoComplete="on">
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField
                                            id="name"
                                            label="Full Name"
                                            error={showErrors && name === ''}
                                            helperText={showErrors && name === '' ? 'Your name is required' : null}
                                            onChange={() => setName(document.getElementById('name').value)}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField
                                            id="email"
                                            label="Email Address"
                                            error={showErrors && email === ''}
                                            helperText={showErrors && email === '' ? 'A valid email address is required' : null}
                                            onChange={() => setEmail(document.getElementById('email').value)}
                                        />
                                    </FormControl>
                                    <div className={subject === 'Account request' ? classes.hidden : classes.visible}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="message"
                                                multiline
                                                rows="6"
                                                label="Message"
                                                error={showErrors && message === ''}
                                                helperText={showErrors && message === '' ? 'Please include a message' : null}
                                                onChange={() => setMessage(document.getElementById('message').value)}
                                            />
                                        </FormControl>
                                    </div>
                                </form>
                            </div>
                        </CardContent>
                        <CardActions className={classes.action}>
                            <div className={status === 'sent' ? classes.hidden : classes.visible }>
                                <Button variant="contained" color="primary" className={classes.button} onClick={sendMessage}>Send</Button>
                            </div>
                        </CardActions>
                    </Card>
                </Paper>
            </CommonContainer>
        </div>
    )
}